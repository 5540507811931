// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   selectedMarket: null,
// };

// const marketSlice = createSlice({
//   name: 'market',
//   initialState,
//   reducers: {
//     setSelectedMarket: (state, action) => {
//       state.selectedMarket = action.payload;
//     },
//     clearSelectedMarket: (state) => {
//       state.selectedMarket = null;
//     },
//   },
// });

// export const { setSelectedMarket, clearSelectedMarket } = marketSlice.actions;
// export default marketSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favoriteMarkets: JSON.parse(localStorage.getItem('favoriteMarkets')) || [], // Charger depuis localStorage
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    addFavoriteMarket: (state, action) => {
      if (!state.favoriteMarkets.find(market => market.marche_id === action.payload.marche_id)) {
        state.favoriteMarkets.push(action.payload);
        localStorage.setItem('favoriteMarkets', JSON.stringify(state.favoriteMarkets)); // Mettre à jour localStorage
      }
    },
    removeFavoriteMarket: (state, action) => {
      state.favoriteMarkets = state.favoriteMarkets.filter(
        market => market.marche_id !== action.payload.marche_id
      );
      localStorage.setItem('favoriteMarkets', JSON.stringify(state.favoriteMarkets)); // Mettre à jour localStorage
    },
  },
});

export const { addFavoriteMarket, removeFavoriteMarket } = marketSlice.actions;
export default marketSlice.reducer;
