// // src/redux/authSlice.js

// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//     isAuthenticated: false,
//     user: null
// };

// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         login: (state, action) => {
//             state.isAuthenticated = true;
//             state.user = action.payload; // Assurez-vous que action.payload contient toutes les données utilisateur nécessaires
//         },
//         logout: (state) => {
//             state.isAuthenticated = false;
//             state.user = null;
//         }
//     }
// });

// export const { login, logout } = authSlice.actions;
// export default authSlice.reducer;
// src/redux/authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: localStorage.getItem('remember_token') ? true : false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            // Sauvegarder les informations dans localStorage
            // console.log('Saving to localStorage:', action.payload);

            localStorage.setItem('remember_token', action.payload.remember_token);
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            // Effacer les informations du localStorage
            localStorage.removeItem('remember_token');
            localStorage.removeItem('user');
        },
        updateUser: (state, action) => {
            // Mettre à jour les informations de l'utilisateur dans l'état
            if (state.user) {
                state.user = { ...state.user, ...action.payload };
                // Sauvegarder les informations mises à jour dans localStorage
                localStorage.setItem('user', JSON.stringify(state.user));
            }
        },
   
    }
});

export const { login, logout,updateUser } = authSlice.actions;
export default authSlice.reducer;
