/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import CustomHeader from '../../components/customheader'

import next from '../../images/next.png'
import { Link } from 'react-router-dom';
const LeagueTeams = () => {
  const [equipes, setEquipes] = useState(["Equipe 1"]);

  const creerEquipe = () => {
    const numEquipes = equipes.length + 1;
    const nomEquipe = `Equipe ${numEquipes}`;
    setEquipes([...equipes, nomEquipe]); // Ajoutez le nouveau nom d'équipe à la liste
  };


  return (
    <section className=' bg-[#252f3d] text-white relative h-[100vh]'>
      <Link to='/ligue' className='absolute left '>
        <img class={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className='bg-[#252f3d] text-white flex justify-center'>

        <div className='flex items-center flex-col space-y-3 justify-center w-[100%] sm:w-[70%]  md:w-[80%]   lg:w-[70%]  xl:w-[70%]  2xl:w-[60%] '>
          <CustomHeader width="w-[45%]" />
          <div className='items-center flex justify-center flex-col space-y-7  w-[90%] sm:w-[70%]  md:w-[60%]   lg:w-[60%]  xl:w-[60%]  2xl:w-[60%] '>

            <div className='flex items-center flex-col space-y-3 justify-center'>
              <p className='mt-4 max-w-auto   mx-auto text-center md:text-[19px] sm:text-[19px] lg:text-[19px]  xl:text-[23px]  2xl:text-[23px] items-center text-[14px]  font-semibold  '>"Nom de la ligue"</p>

            </div>
            <div className='flex justify-center items-center w-[70%]'>

              <button className={` hover:bg-gray-400  border rounded-md   w-[100%]  py-2.5  gap-4  pr-4 flex bg-gray-300 text-black justify-center text-center  font-bold text-[15px] md:text-[16px] sm:text-[16px] h-11 lg:text-[16px] xl:text-[16px] 2xl:text-[16px] pl-0`} onClick={creerEquipe}
              >
                Créer une équipe
              </button>
            </div>

            <div className='flex items-center flex-col space-y-3 justify-center w-[100%]'>

            <div className='overflow-y-auto w-full max-h-[43vh]'>
              {equipes.map((equipe, index) => (
                <div key={index} className='flex space-y-3 justify-around items-center w-[100%]'>
                  <p className=' mx-auto text-center  md:text-[17px] sm:text-[17px] lg:text-[17px] xl:text-[17px] 2xl:text-[17px]  text-[15px] items-center w-1/3 font-semibold'>{equipe}</p>
                  <button className="hover:bg-gray-100  md:text-[13px] sm:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[13px] hover:text-black border rounded-md font-bold w-[60%] text-white h-9 text-center items-center flex justify-center py-2 "  >
                    Insérer des membres
                  </button>
                </div>
              ))}
            </div>
            </div>







          </div>


        </div>

      </div>

    </section >

  )
}

export default LeagueTeams