/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'

import CustomInput from '../../components/custominput'
import cleepwd from '../../images/cleepwd.png'
import send from '../../images/send.svg'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../Api/authApi.js';
import { updateUser } from '../../redux/authSlice'; // Importez l'action 
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {
    const navigate = useNavigate(); // Ajouter cette ligne

    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const handleUpdate = async () => {
        if (!password.trim()) {
            alert("Le mot de passe est requis.");
            return;
        }

        try {
            const id = user.id;
            await updatePassword(id, password); // Assurez-vous que votre fonction d'API accepte un seul mot de passe
            alert('Mot de passe mis à jour avec succès');
            navigate('/profil');

            // Mettre à jour les informations de l'utilisateur dans Redux si nécessaire
            dispatch(updateUser({ ...user, password }));
            // Vous pourriez également rediriger ou mettre à jour l'état global si nécessaire
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <section className=' bg-[#252f3d] text-white relative'>
            <Link className='absolute left ' to='/profil'>
                <img class={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
            </Link>
            <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">

                <div className='flex items-center flex-col justify-center  '>
                    <CustomHeader width="w-[60%]" />
                    <div className='flex items-center flex-col space-y-4  w-[90%] sm:w-[70%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
                    <p className=' font-medium  text-center text-sm py-4 '>Inscris ton mot de passe actuel !</p>

                    <div className=' flex-col gap-4 flex items-center my-4 '>
                            <div className="relative w-[100%] lg:w-full items-center flex justify-center ">
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={` pl-12 pr-4 py-2 border rounded-sm text-black  text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black font-medium `}
                                    placeholder="Mot de passe actuel" />
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center  pointer-events-none">
                                  <img src={cleepwd} height="20px" width="20px" ></img>
                                </div>
                            </div>
                            <button
                                className='btn hover:bg-gray-400 border rounded-sm text-black cursor-pointer w-[%] lg:w-full py-1 flex pl-3 gap-6 bg-gray-300 font-medium text-[13px] text-center items-center'
                                onClick={handleUpdate}
                            >
                                <span><svg class="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                                </svg></span>
                                <span className='text-sm'>Confirmer</span>
                            </button>
                        </div>




                    </div>
                    <div className='flex items-center flex-col space-y-7 justify-center mt-8'>
                    <CustomFooter styelefooter='text-justify mx-7 text-[14.5px]' />

                    </div>

                </div>

            </div>
        </section>

    )
}

export default UpdatePassword