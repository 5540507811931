
import React from 'react';

const CustomInput = ({ icon,type, style, placeholder,name,value,onChange, styleinput, ...rest }) => {
    return (
        <>
         
            <div className="relative w-[100%]  ">
                <input
                 type={type}
                    className={` pr-4  py-2 border rounded-sm text-black font-bold text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black ${styleinput}`}
                    style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}
                    placeholder={placeholder} 
                    name={name} 
                    value={value}
                    onChange={onChange}
                    />
                <div className={`absolute inset-y-0 left-0 pl-3  
                    flex items-center ${style} 
                    pointer-events-non`}>
                    {icon}
                </div>
            </div>
        </>
    );
}

export default CustomInput;
