/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";
import * as d3 from 'd3';  // Utiliser d3 pour charger et parser les données CSV
import bitcoin from '../../images/bitcoin.png'
import totalenergielogo from '../../images/imgprof/totalenergielogo.png'
import francelogo from '../../images/drapeaux/francedrapeau.png'
import USD from '../../images/USD.png'
import Ethereum from '../../images/Ethereumimg.png';
import StockChart from "../../components/chartgraphique/StockChart";
import { useParams } from 'react-router-dom';
import {
  getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getDatachartjb1m,
  getDataForCurrentAndLast10Minutes, postVente,
} from '../../Api/authApi.js';
import axios from 'axios'; // Import Axios
import { useNavigate ,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const PorteFeuilleDetail = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate(); // Ajouter cette ligne
  const [selectedTime, setSelectedTime] = useState('10m');
  const location = useLocation(); // Récupérer `location` pour accéder aux données de `state`





  const [marches, setMarches] = useState([]);
  const [marchesid, setmarchesid] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMarche, setSelectedMarche] = useState(null);
  const [marketData, setMarketData] = useState({}); // Ajouter l'état pour les données du marché
  const [marketImages, setMarketImages] = useState({});
  const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
  const [countries, setCountries] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [data, setData] = useState(null); // État pour stocker les données récupérées
  const [leagueid, setleagueid] = useState(0);
  const userId = useSelector((state) => state.auth.user.id);
  const selectedMarketredux = useSelector((state) => state.market.selectedMarket);
  const favoriteMarkets = useSelector((state) => state.market.favoriteMarkets);


  useEffect(() => {
    const fetchMarches = async () => {
      try {
        setLoading(true);
        const data = await getMarches(userId);
        setMarches(data);
  
        let selectedMarket;
  
        // 1. Utiliser le favori passé via `location.state` s'il existe
        if (favoriteMarkets.length > 0) {
          // Sélectionner le premier favori
          selectedMarket = favoriteMarkets[0];
        } else if (location.state?.selectedFavorite) {
          // 2. Si `favoriteMarkets` est vide, vérifier `location.state.selectedFavorite`
          selectedMarket = location.state.selectedFavorite;
        } else {
          // Si aucun favori n'est trouvé, afficher un message d'erreur
          setError("Aucun marché favori disponible. Veuillez sélectionner un marché.");
        }
  
        // Si un marché sélectionné est défini, charger les données enrichies
        if (selectedMarket) {
          const enrichedData = await getDataForCurrentMinutes(selectedMarket.marche_id, selectedMarket.league_id);
  
          setSelectedMarche({
            ...selectedMarket,
            current_value: enrichedData.value,
            last_value: enrichedData.last_value,
            difference: enrichedData.difference,
            heure_complete: enrichedData.heure_complete,
          });
  
          setmarchesid(selectedMarket.marche_id);
          setleagueid(selectedMarket.league_id);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (userId) {
      fetchMarches();
    }
  }, [userId, location.state]);


  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
        // console.log(response)
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);

  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
        // console.log(response)
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const markets = await getMarchesByType('Actions');
        // console.log(markets);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchMarkets();
  }, []);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`https://tradingseries.fr/admin/public/api/marches/countries`, {
          params: { type }
        });
        setCountries(response.data);
        // console.log(response.data);
      } catch (error) {
        setError(error.message);
        console.error(error.message);
      }
    };

    fetchCountries();
  }, [type]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    const fetchMarches = async () => {
      setLoading(true);
      try {
        // Récupérer les marchés de l'utilisateur
        const data = await getMarches(userId);
        setMarches(data);
  
        let selectedMarket;
  
        // 1. Vérifier si un marché favori est passé via `location.state`
        if (location.state?.selectedFavorite) {
          selectedMarket = location.state.selectedFavorite;
        } else {
          // 2. Rechercher les marchés favoris dans `data`
          const favoriteMarkets = data.filter(market => market.is_favorite && market.marche_id && market.league_id);
  
          if (favoriteMarkets.length > 0) {
            // Si des favoris existent, sélectionner le premier favori
            selectedMarket = favoriteMarkets[0];
          }
        }
  
        // Si aucun marché favori n'est trouvé, afficher un message
        if (!selectedMarket) {
          setError("Pas de marché favori disponible. Veuillez sélectionner un marché.");
        } else {
          // Sinon, charger les données enrichies pour le marché favori sélectionné
          const enrichedData = await getDataForCurrentMinutes(selectedMarket.marche_id, selectedMarket.league_id);
  
          setSelectedMarche({
            ...selectedMarket,
            current_value: enrichedData.value,
            last_value: enrichedData.last_value,
            difference: enrichedData.difference,
            heure_complete: enrichedData.heure_complete,
          });
  
          setmarchesid(selectedMarket.marche_id);
          setleagueid(selectedMarket.league_id);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (userId) {
      fetchMarches();
    }
  }, [userId, location.state]);
  

  const handleAchat = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postAchat(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_achat', {
        state: { marche_id, titre, icon, value, hour: heure_complete, difference, type, league_id }
      });
    } catch (error) {
      console.error('Erreur lors de l\'achat:', error);
    }
  };

  const handleVente = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postVente(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_vente', {
        state: { marche_id, titre, icon, value, hour: heure_complete, difference, type, league_id }
      });
    } catch (error) {
      console.error('Erreur lors de la vente:', error);
    }
  };



  if (!selectedMarche) return null;

  const { id, icon, titre } = selectedMarche;
  const { hour, minute, value, date, difference } = marketData[id] || {};

  const queryParams = new URLSearchParams({
    id,
    logo: encodeURIComponent(icon),
    titre: encodeURIComponent(titre),
    hour: hour || 'N/A',
    minute: minute || 'N/A',
    value: value || 'N/A',
    date: date || 'N/A'
  }).toString();
  // console.log(queryParams)

  const latestValue = marketData && marketData.values && marketData.values.length > 0
    ? marketData.values[0].value
    : 'N/A';

  const marketPageMap = {
    "Actions": "/portefeuille_actions/Actions",
    "Matieres-premiéres": "/portefeuille_actions/Matieres-premieres",
    "Crypto-monnaies": "/portefeuille_actions/Crypto-monnaies",
    "Devises": "/portefeuille_actions/Devises"
  };

  return (
    <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] py-7 pb-12">
      <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] w-[100%] p-1 lg:border border-gray-400 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col border-b ">

          {selectedMarche && (
            <div className=" flex  justify-between gap-1 items-center py-4">
              <div className='flex gap-2 flex-row justify-center text-center items-center'>
                <img
                  src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`}
                  // src={`https://tradingseries.fr/admin/storage/app/public/${marketToDisplay.icon}`} 

                  className="w-9 bg-transparent rounded-full"
                  alt={selectedMarche.titre}
                />
                <p className="text-[14px] font-bold">{selectedMarche.titre}</p>
              </div>

            </div>
          )}
          <StockChart marcheId={marchesid} leagueid={leagueid} />

          <div className='flex justify-center items-center'>
            {selectedMarche && (
              <div className=" flex  justify-between gap-1 items-center py-4">

                <div className="flex gap-2">
                  <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
                    ACHAT
                  </button>
                  <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
                    VENTE
                  </button>
                </div>
              </div>
            )}

          </div>
          {/* <div className="flex flex-col gap-3 py-2">
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Volume (3M) :</p>
                            <p>3,98M</p>
                        </div>
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Capitalisation boursière :</p>
                            <p>  143,99B</p>
                        </div>
                    </div> */}


        </div>
        <div className="lg:w-[98%] w-[98%] p-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex py-2 lg:pt-20 mx-auto   flex-col  ">
          <div className="flex  flex-col gap-3 mx-3">
            {selectedMarche && (

              <>
                <p className="text-lg font-medium  text-justify">Profil</p>

                <p className="text-[13px] lg:text-lg font-medium  text-justify">{selectedMarche.description}</p></>

            )}
          </div>
        </div>


      </div>
    </div>

  )
};
export default PorteFeuilleDetail;
