
// import React, { PureComponent } from 'react';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
// import bitcoin from '../../../images/bitcoin.png';
// import USD from '../../../images/USD.png';
// import totalenergies from '../../../images/totalenergie.png';
// import Ethereum from '../../../images/Ethereumimg.png';

// const data = [
//     { name: 'Ethereum', value: 17, img: Ethereum },
//     { name: 'Total Energies', value: 35, img: totalenergies },
//     { name: 'EUR/USD', value: 20, img: USD },
//     { name: 'Bitcoin', value: 28, img: bitcoin },
// ];


// const COLORS = ['#999fa6', '#42494f', '#b3b9bf', '#63686e'];

// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     const labelRadius = outerRadius + 33;
//     const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
//     const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

//     return (
//         <g>
//             <text x={labelX} y={labelY - 5} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={11}>
//                 {data[index].name}
//             </text>
//             <text x={labelX} y={labelY + 7} fill="#fff" textAnchor="middle" dominantBaseline="central"  fontSize={12}>
//                 {`${(percent * 100).toFixed(0)}%`}
//             </text>

//             <image
//                 href={data[index].img}
//                 x={x - 15}
//                 y={y - 15}
//                 width={30}
//                 height={24}
//                 preserveAspectRatio="xMidYMid slice"
//             />
//         </g>
//     );
// };

// export default class Example extends PureComponent {
//     render() {
//         return (
//             <ResponsiveContainer width="100%" height={300}>
//                 <PieChart>
//                     <Pie
//                         data={data}
//                         cx="50%"
//                         cy="50%"
//                         labelLine={true}
//                         label={renderCustomizedLabel}
//                         outerRadius={100}
//                         fill="black"
//                         dataKey="value"

//                     >
//                         {data.map((entry, index) => (
//                             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                         ))}
//                     </Pie>
//                 </PieChart>
//             </ResponsiveContainer>
//         );
//     }
// }
// import React, { useEffect, useState } from 'react';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
// import { useSelector } from 'react-redux';
// import { getTransactionsParMarche } from '../../../Api/authApi.js';

// const COLORS = ['#999fa6', '#42494f', '#b3b9bf', '#63686e'];
// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     const labelRadius = outerRadius + 33;
//     const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
//     const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

//     const baseURL = 'http://127.0.0.1:8000/api'; // URL de base
//     // const baseURL = 'https://tradingseries.fr/admin/public'; // URL de base

//     return (
//         <g>
//             <text x={labelX} y={labelY - 5} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={11}>
//                 {data[index].market_name}
//             </text>
//             <text x={labelX} y={labelY + 7} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={12}>
//                 {`${data[index].value.toFixed(2)}%`}
//             </text>

//             <image
//                 href={`${baseURL}${data[index].market_icon}`} // Concaténation de l'URL de base et du chemin de l'image
//                 x={x - 15}
//                 y={y - 15}
//                 width={30}
//                 height={24}
//                 preserveAspectRatio="xMidYMid slice"
//             />
//         </g>
//     );
// };

// export default function Example() {
//     const [portefeuille, setPortefeuille] = useState({});
//     const [transactions, setTransactions] = useState([]);
//     const [error, setError] = useState(null);
//     const user = useSelector((state) => state.auth.user);

//     useEffect(() => {
//         const fetchPortefeuilleData = async () => {
//             if (user) {
//                 try {
//                     const data = await getTransactionsParMarche(user.id);
//                     setPortefeuille(data.portefeuille);
//                     setTransactions(data.ventes_par_marche);
//                     console.log('hhh',data.ventes_par_marche);
//                 } catch (error) {
//                     setError(error.message);
//                 }
//             }
//         };

//         fetchPortefeuilleData();
//     }, [user]);

//     // Calculer le montant total investi
//     const totalInvested = transactions.reduce((sum, transaction) => sum + transaction.total_montant_investi, 0);

//     // Mettre en forme les données pour le PieChart avec le pourcentage calculé
//     const formattedData = transactions.map(transaction => ({
//         market_name: transaction.market_name,
//         value: (transaction.total_montant_investi / totalInvested) * 100, // Calcul du pourcentage par rapport au total
//         market_icon: transaction.market_icon
//     }));

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <PieChart>
//                 <Pie
//                     data={formattedData}
//                     cx="50%"
//                     cy="50%"
//                     labelLine={true}
//                     label={(props) => renderCustomizedLabel(props, formattedData)}
//                     outerRadius={100}
//                     fill="black"
//                     dataKey="value"
//                 >
//                     {formattedData.map((entry, index) => (
//                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                     ))}
//                 </Pie>
//             </PieChart>
//         </ResponsiveContainer>
//     );
// }
// import React, { useEffect, useState } from 'react';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
// import { useSelector } from 'react-redux';
// import { getTransactionsParMarche } from '../../../Api/authApi.js';

// const COLORS = ['#999fa6', '#42494f', '#b3b9bf', '#63686e'];
// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     const labelRadius = outerRadius + 33;
//     const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
//     const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

//     const baseURL = 'http://127.0.0.1:8000/api'; // URL de base
//     // const baseURL = 'https://tradingseries.fr/admin/public'; // URL de base

//     return (
//         <g>
//             <text x={labelX} y={labelY - 5} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={11}>
//                 {data[index].market_name}
//             </text>
//             <text x={labelX} y={labelY + 7} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={12}>
//                 {`${data[index].value.toFixed(2)}%`}
//             </text>

//             <image
//                 href={`${baseURL}${data[index].market_icon}`} // Concaténation de l'URL de base et du chemin de l'image
//                 x={x - 15}
//                 y={y - 15}
//                 width={30}
//                 height={24}
//                 preserveAspectRatio="xMidYMid slice"
//             />
//         </g>
//     );
// };

// export default function Example() {
//     const [portefeuille, setPortefeuille] = useState({});
//     const [transactions, setTransactions] = useState([]);
//     const [error, setError] = useState(null);
//     const user = useSelector((state) => state.auth.user);

//     useEffect(() => {
//         const fetchPortefeuilleData = async () => {
//             if (user) {
//                 try {
//                     const data = await getTransactionsParMarche(user.id);
//                     setPortefeuille(data.portefeuille);
//                     setTransactions(data.ventes_par_marche);
//                     console.log('hhh',data.ventes_par_marche);
//                 } catch (error) {
//                     setError(error.message);
//                 }
//             }
//         };

//         fetchPortefeuilleData();
//     }, [user]);

//     // Calculer le montant total investi
//     const totalInvested = transactions.reduce((sum, transaction) => sum + transaction.total_montant_investi, 0);

//     // Mettre en forme les données pour le PieChart avec le pourcentage calculé
//     const formattedData = transactions.map(transaction => ({
//         market_name: transaction.market_name,
//         value: (transaction.total_montant_investi / totalInvested) * 100, // Calcul du pourcentage par rapport au total
//         market_icon: transaction.market_icon
//     }));

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <PieChart>
//                 <Pie
//                     data={formattedData}
//                     cx="50%"
//                     cy="50%"
//                     labelLine={true}
//                     label={(props) => renderCustomizedLabel(props, formattedData)}
//                     outerRadius={100}
//                     fill="black"
//                     dataKey="value"
//                 >
//                     {formattedData.map((entry, index) => (
//                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                     ))}
//                 </Pie>
//             </PieChart>
//         </ResponsiveContainer>
//     );
// }
// import React, { useEffect, useState } from 'react';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
// import { useSelector } from 'react-redux';
// import { getTransactionsParMarche,getAllTransactionsBymarket } from '../../../Api/authApi.js';

// const COLORS = ['#999fa6', '#42494f', '#b3b9bf', '#63686e'];
// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     const labelRadius = outerRadius + 33;
//     const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
//     const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

//     const baseURL = 'https://tradingseries.fr/admin/storage/app/public/'; // URL de base
//     // const baseURL = 'http://127.0.0.1:8000'; // URL de base

//     return (
//         <g>
//             <text x={labelX} y={labelY - 5} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={11}>
//                 {data[index].market_name}
//             </text>
//             <text x={labelX} y={labelY + 7} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={12}>
//                 {`${data[index].value.toFixed(2)}%`}
//             </text>
//             <defs>
//                 <clipPath id={`clip-${index}`}>
//                     <circle cx={x} cy={y} r={37} /> {/* Rayon du cercle */}
//                 </clipPath>
//             </defs>
//             <image
//                 href={`${baseURL}${data[index].market_icon}`} // Concaténation de l'URL de base et du chemin de l'image
//                 x={x - 15}
//                 y={y - 15}
//                 width={30}
//                 height={24}
//                 preserveAspectRatio="xMidYMid slice"
//                 clipPath={`url(#clip-${index})`} // Appliquez le masque

//             />
//         </g>
//     );
// };

// export default function Example() {
//     const [portefeuille, setPortefeuille] = useState({});
//     const [transactions, setTransactions] = useState([]); // Initialiser avec un tableau vide
//     const [error, setError] = useState(null);
//     const user = useSelector((state) => state.auth.user);

//     useEffect(() => {
//         const fetchPortefeuilleData = async () => {
//             if (user) {
//                 try {
//                     const data = await getAllTransactionsBymarket(user.id);
//                     setPortefeuille(data.portefeuille);
//                     setTransactions(data.transactions_par_marche || []); // Assurez-vous d'initialiser en tableau vide si undefined
//                     // console.log('Transactions par marché:', data.transactions_par_marche);
//                 } catch (error) {
//                     setError(error.message);
//                 }
//             }
//         };

//         fetchPortefeuilleData();
//     }, [user]);

//     // Vérifier que transactions est un tableau avant de calculer le montant total investi
//     const totalInvested = Array.isArray(transactions) ? transactions.reduce((sum, transaction) => sum + transaction.total_montant_investi, 0) : 0;

//     // S'assurer que totalInvested n'est pas 0 pour éviter les erreurs de division
//     if (totalInvested === 0) return <div>Aucune transaction  disponible..</div>;

//     // Mettre en forme les données pour le PieChart avec le pourcentage calculé
//     const formattedData = transactions.map(transaction => ({
//         market_name: transaction.market_name,
//         value: (transaction.total_montant_investi / totalInvested) * 100, // Calcul du pourcentage par rapport au total
//         market_icon: transaction.market_icon
//     }));

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <PieChart>
//                 <Pie
//                     data={formattedData}
//                     cx="50%"
//                     cy="50%"
//                     labelLine={true}
//                     label={(props) => renderCustomizedLabel(props, formattedData)}
//                     outerRadius={100}
//                     fill="black"
//                     dataKey="value"
//                 >
//                     {formattedData.map((entry, index) => (
//                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                     ))}
//                 </Pie>
//             </PieChart>
//         </ResponsiveContainer>
//     );
// }
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import { getAllTransactionsBymarket } from '../../../Api/authApi.js';

const COLORS = ['#42494f', '#999fa6', '#b3b9bf', '#63686e']; // Couleurs du plus grand au plus petit montant
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const labelRadius = outerRadius + 33;
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

    const baseURL = 'https://tradingseries.fr/admin/storage/app/public/'; // URL de base pour les icônes des marchés

    return (
        <g>
            <text x={labelX} y={labelY - 5} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={11}>
                {data[index].market_name}
            </text>
            <text x={labelX} y={labelY + 7} fill="#fff" textAnchor="middle" dominantBaseline="central" fontSize={12}>
                {`${data[index].value.toFixed(2)}%`}
            </text>
            <defs>
                <clipPath id={`clip-${index}`}>
                    <circle cx={x} cy={y} r={37} /> {/* Rayon du cercle */}
                </clipPath>
            </defs>
            <image
                href={`${baseURL}${data[index].market_icon}`} // URL de l'image du marché
                x={x - 15}
                y={y - 15}
                width={30}
                height={24}
                preserveAspectRatio="xMidYMid slice"
                clipPath={`url(#clip-${index})`} // Masque pour l'image
            />
        </g>
    );
};

export default function Example() {
    const [portefeuille, setPortefeuille] = useState({});
    const [transactions, setTransactions] = useState([]); // Tableau des transactions par marché
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            if (user) {
                try {
                    const data = await getAllTransactionsBymarket(user.id);
                    setPortefeuille(data.portefeuille);
                    setTransactions(data.transactions_by_market || []); // Récupérer les transactions par marché
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchPortefeuilleData();
    }, [user]);

    // Calcul du montant total investi
    const totalInvested = Array.isArray(transactions) ? transactions.reduce((sum, transaction) => sum + transaction.montant_total, 0) : 0;

    // Si aucune transaction n'est disponible, afficher un message
    if (totalInvested === 0) return <div>Aucune transaction disponible..</div>;

    // Trier les transactions par montant décroissant
    const sortedTransactions = transactions.sort((a, b) => b.montant_total - a.montant_total);

    // Formater les données pour le PieChart avec le pourcentage calculé
    const formattedData = sortedTransactions.map(transaction => ({
        market_name: transaction.market_name,
        value: (transaction.montant_total / totalInvested) * 100, // Calcul du pourcentage par rapport au total investi
        market_icon: transaction.market_icon
    }));

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie
                    data={formattedData}
                    cx="52%"
                    cy="45%"
                    labelLine={true}
                    label={(props) => renderCustomizedLabel(props, formattedData)}
                    outerRadius={90}
                    fill="black"
                    dataKey="value"
                >
                    {formattedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
