


// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId,userId ,leagueid}) => {
//   const [dps1, setDps1] = useState([]); // Stocker les données récupérées
//   const [chart, setChart] = useState(null); // Stocker le graphique
//   const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
//   const [selectedPeriod, setSelectedPeriod] = useState('1min'); // Période sélectionnée

//   useEffect(() => {
//     // Initialiser le graphique lors du premier rendu
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: { crosshair: { enabled: false, snapToDataPoint: true, }, labelFontColor: "#5a5a5a", lineColor: "#5a5a5a", tickColor: "#yellow",       labelFormatter: function (e) {
//           const date = new Date(e.value);  // e.value correspond à l'heure
//           let hours = date.getHours();  // Récupère les heures en format 24h
//           const minutes = date.getMinutes();  // Récupère les minutes
//           const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
//           return formattedTime;
//         }},

//         axisY: { prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent" ,labelFormatter: function(e) {
//           return "";
//       }},
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render(); // Afficher le graphique

//     // Charger les données par défaut et toutes les 60 secondes
//     fetchData('All'); // Charger les données par défaut pour 30 minutes
//     const interval = setInterval(() => {
//       fetchData(selectedPeriod); // Re-fetch les données toutes les 60 secondes
//     }, 60000); // 60 secondes

//     return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
//   }, []);
//   // const API_URL = 'https://tradingseries.fr/admin/public/api';
//   const API_URL = 'http://localhost:8000/api/';

//   // Fonction pour récupérer les données de l'API
//   const fetchData = async (period) => {
//     setLoading(true); // Afficher le loader
//     let url = "";



//     switch (period) {
//       case 'All':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       case '240':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/240`;
//         break;
//       case '300':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/300`;
//         break;
//       case '1Y':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       case '5Y':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       default:
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//     }

//     try {
//       const response = await fetch(url);
//       const data = await response.json();

//       const dataPoints = data.values.map(item => {
//         let high = Number(item.high);
//         let low = Number(item.low);

//         // Si la différence entre high et low est trop faible, ajouter une marge artificielle
//         const difference = high - low;
//         const minDifference = 0.2; // Seuil minimum pour la différence

//         if (difference < minDifference) {
//           const margin = (minDifference - difference) / 2;
//           high += margin;
//           low -= margin;
//         }

//         return {
//           x: new Date(`${data.date} ${item.minute}`),
//           y: [Number(item.open), high, low, Number(item.close)],
//           color: item.color
//         };
//       });

//       setDps1(dataPoints); // Mettre à jour les data points
//       updateChart(dataPoints); // Mettre à jour rapidement le graphique
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false); // Cacher le loader
//     }
//   };

//   // Fonction pour mettre à jour les données du graphique
//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints; // Mettre à jour les points de données
//       chart.render(); // Réafficher le graphique avec les nouvelles données
//     }
//   };

//   // Gestion de la sélection de la période
//   const handlePeriodSelect = (period) => {
//     setSelectedPeriod(period); // Mettre à jour la période sélectionnée
//     fetchData(period); // Charger les nouvelles données pour la période sélectionnée
//   };

//   return (
//     <div>
//       {loading && <p>Chargement des données...</p>} {/* Afficher un loader pendant le chargement */}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         <button
//           style={selectedPeriod === '1m' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1M
//         </button>
//         <button
//           style={selectedPeriod === '10min' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('240')}
//         >
//           10M
//         </button>
//         <button
//           style={selectedPeriod === '30min' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('300')}
//         >
//           30M
//         </button>
//         <button
//           style={selectedPeriod === 'hour' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1H
//         </button>
//         <button
//           style={selectedPeriod === 'day' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1J
//         </button>
//       </div>
//     </div>
//   );
// };

// // Styles pour les boutons
// const buttonStyle = {
//   padding: '1px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d', // Couleur différente pour le bouton sélectionné
//   color: 'white',

// };

// export default StockChart;
// version 2
// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId, userId, leagueid }) => {
//   const [dps1, setDps1] = useState([]); // Stocker les données récupérées
//   const [chart, setChart] = useState(null); // Stocker le graphique
//   const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
//   const [selectedPeriod, setSelectedPeriod] = useState('200'); // Période sélectionnée

//   useEffect(() => {
//     // Initialiser le graphique lors du premier rendu
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: {
//           crosshair: { enabled: false, snapToDataPoint: true },
//           labelFontColor: "#5a5a5a",
//           lineColor: "#5a5a5a",
//           tickColor: "#yellow",
//           // labelFormatter: function (e) {
//           //   const date = new Date(e.value);  // e.value correspond à l'heure
//           //   let hours = date.getHours();  // Récupère les heures en format 24h
//           //   const minutes = date.getMinutes();  // Récupère les minutes
//           //   const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
//           //   return formattedTime;
//           // }
//           labelFormatter: function (e) {
//             return "";
//           }
//         },
//         axisY: {
//           prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent",
//           labelFormatter: function (e) {
//             return "";
//           }
//         },
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,

//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render(); // Afficher le graphique

//     // Charger les données par défaut
//     fetchData(selectedPeriod);

//     const interval = setInterval(() => {
//       fetchData(selectedPeriod); // Re-fetch les données toutes les 60 secondes
//     }, 60000); // 60 secondes

//     return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
//   }, [selectedPeriod, marcheId, leagueid]);

//   const API_URL = 'http://localhost:8000/api/';
//   // const API_URL = 'https://tradingseries.fr/admin/public/api/';

//   // Fonction pour récupérer les données de l'API
//   const fetchData = async (period) => {
//     // console.log(`Fetching data for period: ${period}`); // Ajoutez cette ligne pour déboguer

//     setLoading(true); // Afficher le loader
//     let url = "";

//     switch (period) {
//       case '200':
//         url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/200`;
//         break;
//       case '240':
//         url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/240`;
//         break;
//       case '300':
//         url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/300`;
//         break;
//       case '340':
//         url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/340`;
//         break;

//       default:
//         url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//     }

//     try {
//       const response = await fetch(url);
//       const data = await response.json();
//       // console.log('Data received:', data); // Loguer les données pour voir ce qui est retourné

//       const dataPoints = data.values.map(item => {
//         let high = Number(item.high);
//         let low = Number(item.low);

//         // Si la différence entre high et low est trop faible, ajouter une marge artificielle
//         const difference = high - low;
//         const minDifference = 0.04; // Seuil minimum pour la différence

//         if (difference < minDifference) {
//           const margin = (minDifference - difference) / 2;
//           high += margin;
//           low -= margin;
//         }

//         return {
//           x: new Date(`${data.date} ${item.minute}`),
//           y: [Number(item.open), high, low, Number(item.close)],
//           color: item.color
//         };
//       });

//       setDps1(dataPoints); // Mettre à jour les data points
//       updateChart(dataPoints); // Mettre à jour rapidement le graphique
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false); // Cacher le loader
//     }
//   };

//   // Fonction pour mettre à jour les données du graphique
//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints; // Mettre à jour les points de données
//       chart.render(); // Réafficher le graphique avec les nouvelles données
//     }
//   };

//   // Gestion de la sélection de la période
//   const handlePeriodSelect = (period) => {
//     setSelectedPeriod(period); // Mettre à jour la période sélectionnée
//     fetchData(period); // Charger les nouvelles données pour la période sélectionnée
//   };

//   return (
//     // Pour les temps des graphismes nous proposons afin de ne pas toucher à la base : 1m =1j, 10mn = 1w, 30 mn = 1M, 1h = 1Y,  1j = 5Y pour garder les 5 divisions, on pourra revoir les divisions (6.7.8...) en V2.




//     <div>
//       {loading && <p>Chargement des données...</p>} {/* Afficher un loader pendant le chargement */}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         <button
//           style={selectedPeriod === '200' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('200')}
//         >
//           5h
//         </button>
//         <button
//           style={selectedPeriod === '240' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('240')}
//         >
//           1J
//         </button>
//         <button
//           style={selectedPeriod === '300' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('300')}
//         >
//           1W
//         </button>
//         <button
//           style={selectedPeriod === '340' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('340')}
//         >
//           1M
//         </button>
//         <button
//           style={selectedPeriod === 'All' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           Max
//         </button>
//       </div>
//     </div>
//   );
// };

// // Styles pour les boutons
// const buttonStyle = {
//   padding: '1px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d', // Couleur différente pour le bouton sélectionné
//   color: 'white',
// };

// export default StockChart;
// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId, leagueid }) => {
//   const [dps1, setDps1] = useState([]); // Stocker les données récupérées
//   const [chart, setChart] = useState(null); // Stocker le graphique
//   const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
//   const [selectedInterval, setSelectedInterval] = useState('5h'); // Intervalle sélectionné

//   const intervals = {
//     '5h': '5h',
//     '1j': '1j',
//     '1w': '1w',
//     '1m': '1m',
//     '1y': '1y',
//     '5y': '5y',
//     '10y': '10y',
//     '25y': '25y',
//   };

//   useEffect(() => {
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: {
//           crosshair: { enabled: false, snapToDataPoint: true },
//           labelFontColor: "#5a5a5a",
//           lineColor: "#5a5a5a",
//           tickColor: "yellow",
//           labelFormatter: function (e) {
//             if (['5h', '1j', '1w', '1m'].includes(selectedInterval)) {
//               return `nbjourVirtuel: ${e.value}`; // Affiche nbjourVirtuel pour les périodes courtes
//             } else if (['1y', '5y', '10y', '25y'].includes(selectedInterval)) {
//               return `Mois: ${e.value}`; // Affiche mois pour les périodes longues
//             }
//             return ""; // Valeur par défaut si intervalle non défini
//           }
//         },
//         axisY: {
//           prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent",
//           labelFormatter: function () {
//             return "";
//           }
//         },
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render(); // Afficher le graphique

//     fetchData(selectedInterval);

//     const interval = setInterval(() => {
//       fetchData(selectedInterval); // Re-fetch les données toutes les 60 secondes
//     }, 60000); // 60 secondes

//     return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
//   }, [selectedInterval, marcheId, leagueid]);

//   const API_URL = 'http://localhost:8000/api/';

//   const fetchData = async (interval) => {
//     setLoading(true); // Afficher le loader
//     let url = `${API_URL}getDataFromLastPeriodbougiesvertuel/${marcheId}/${leagueid}/${interval}`;

//     try {
//       const response = await fetch(url);
//       const data = await response.json();
//       console.log('adaabougue',data)

//       if (data.error) {
//         console.error("Erreur API :", data.error);
//         setLoading(false);
//         return;
//       }

//       let dataPoints = [];

//       if (['5h', '1j', '1w', '1m'].includes(interval)) {
//         // Périodes courtes
//         dataPoints = data.values.map(item => {
//           let high = Number(item.high);
//           let low = Number(item.low);
//           const minDifference = 0.04;

//           if ((high - low) < minDifference) {
//             const margin = (minDifference - (high - low)) / 2;
//             high += margin;
//             low -= margin;
//           }

//           return {
//             x: item.nbjourVirtuel, // Utiliser nbjourVirtuel comme axe X pour les périodes courtes
//             y: [Number(item.open), high, low, Number(item.close)],
//             color: item.color,
//             label: `nbjourVirtuel: ${item.nbjourVirtuel}`
//           };
//         });
//       } else {
//         // Périodes longues
//         dataPoints = [];
//         data.values.forEach(yearData => {
//           yearData.mois_values.forEach(monthData => {
//             let high = Number(monthData.high);
//             let low = Number(monthData.low);
//             const minDifference = 0.04;

//             if ((high - low) < minDifference) {
//               const margin = (minDifference - (high - low)) / 2;
//               high += margin;
//               low -= margin;
//             }

//             dataPoints.push({
//               x: monthData.mois, // Utiliser mois comme axe X pour les périodes longues
//               y: [Number(monthData.open), high, low, Number(monthData.close)],
//               color: monthData.color,
//               label: `Mois: ${monthData.mois}`
//             });
//           });
//         });
//       }

//       setDps1(dataPoints);
//       updateChart(dataPoints);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints;
//       chart.render();
//     }
//   };

//   const handleIntervalSelect = (interval) => {
//     setSelectedInterval(interval);
//     fetchData(interval);
//   };

//   return (
//     <div>
//       {loading && <p>Chargement des données...</p>}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         {Object.keys(intervals).map(interval => (
//           <button
//             key={interval}
//             style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
//             onClick={() => handleIntervalSelect(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// const buttonStyle = {
//   padding: '5px 10px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d',
//   color: 'white',
// };

// export default StockChart;
// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId, leagueid }) => {
//   const [dps1, setDps1] = useState([]); // Stocker les données récupérées
//   const [chart, setChart] = useState(null); // Stocker le graphique
//   const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
//   const [selectedInterval, setSelectedInterval] = useState('5h'); // Intervalle sélectionné

//   const intervals = {
//     '5h': '5h',
//     '1j': '1j',
//     '1w': '1w',
//     '1m': '1m',
//     '1y': '1y',
//     '5y': '5y',
//     '10y': '10y',
//     '25y': '25y',
//   };

//   useEffect(() => {
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: {
//           crosshair: { enabled: false, snapToDataPoint: true },
//           labelFontColor: "#5a5a5a",
//           lineColor: "#5a5a5a",
//           tickColor: "yellow",
//           labelFormatter: function (e) {
//             if (['5h', '1j', '1w', '1m'].includes(selectedInterval)) {
//               return `nbjourVirtuel: ${e.value}`; // Affiche nbjourVirtuel pour les périodes courtes
//             } else if (['5y', '10y', '25y'].includes(selectedInterval)) {
//               // Affiche seulement chaque troisième mois pour les périodes longues
//               const month = e.value % 12 + 1; // Mois actuel
//               const year = Math.floor(e.value / 12) + 2020; // Année de départ (par exemple, 2020)
//               return month % 3 === 0 ? `${year} - Mois ${month}` : ""; // Affiche année et chaque troisième mois
//             }
//             return ""; // Valeur par défaut si intervalle non défini
//           }
//         },
//         axisY: {
//           prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent",
//           labelFormatter: function () {
//             return "";
//           }
//         },
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render(); // Afficher le graphique

//     fetchData(selectedInterval);

//     const interval = setInterval(() => {
//       fetchData(selectedInterval); // Re-fetch les données toutes les 60 secondes
//     }, 60000); // 60 secondes

//     return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
//   }, [selectedInterval, marcheId, leagueid]);

//   const API_URL = 'http://localhost:8000/api/';

//   const fetchData = async (interval) => {
//     setLoading(true); // Afficher le loader
//     let url = `${API_URL}getDataFromLastPeriodbougiesvertuel/${marcheId}/${leagueid}/${interval}`;

//     try {
//       const response = await fetch(url);
//       const data = await response.json();
// console.log('databouugeu',data)
//       if (data.error) {
//         console.error("Erreur API :", data.error);
//         setLoading(false);
//         return;
//       }

//       let dataPoints = [];

//       if (['5h', '1j', '1w', '1m'].includes(interval)) {
//         // Périodes courtes
//         dataPoints = data.values.map(item => {
//           let high = Number(item.high);
//           let low = Number(item.low);
//           const minDifference = 0.04;

//           if ((high - low) < minDifference) {
//             const margin = (minDifference - (high - low)) / 2;
//             high += margin;
//             low -= margin;
//           }

//           return {
//             x: item.nbjourVirtuel, // Utiliser nbjourVirtuel comme axe X pour les périodes courtes
//             y: [Number(item.open), high, low, Number(item.close)],
//             color: item.color,
//             label: `nbjourVirtuel: ${item.nbjourVirtuel}`
//           };
//         });
//       } else {
//         // Périodes longues
//         dataPoints = [];
//         data.values.forEach(yearData => {
//           yearData.mois_values.forEach(monthData => {
//             let high = Number(monthData.high);
//             let low = Number(monthData.low);
//             const minDifference = 0.04;

//             if ((high - low) < minDifference) {
//               const margin = (minDifference - (high - low)) / 2;
//               high += margin;
//               low -= margin;
//             }

//             dataPoints.push({
//               x: monthData.mois, // Utiliser mois comme axe X pour les périodes longues
//               y: [Number(monthData.open), high, low, Number(monthData.close)],
//               color: monthData.color,
//               label: `Mois: ${monthData.mois}`
//             });
//           });
//         });
//       }

//       setDps1(dataPoints);
//       updateChart(dataPoints);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints;
//       chart.render();
//     }
//   };

//   const handleIntervalSelect = (interval) => {
//     setSelectedInterval(interval);
//     fetchData(interval);
//   };

//   return (
//     <div>
//       {loading && <p>Chargement des données...</p>}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         {Object.keys(intervals).map(interval => (
//           <button
//             key={interval}
//             style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
//             onClick={() => handleIntervalSelect(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// const buttonStyle = {
//   padding: '5px 10px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d',
//   color: 'white',
// };

// export default StockChart;
// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId, leagueid }) => {
//   const [dps1, setDps1] = useState([]);
//   const [chart, setChart] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [selectedInterval, setSelectedInterval] = useState('5h');

//   const intervals = {
//     '5h': '5h',
//     '1j': '1j',
//     '1w': '1w',
//     '1m': '1m',
//     '1y': '1y',
//     '5y': '5y',
//     '10y': '10y',
//     '25y': '25y',
//   };

//   useEffect(() => {
//     initializeChart();
//     fetchData(selectedInterval);

//     const interval = setInterval(() => {
//       fetchData(selectedInterval);
//     }, 60000);

//     return () => clearInterval(interval);
//   }, [selectedInterval, marcheId, leagueid]);

//   const initializeChart = () => {
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: {
//           crosshair: { enabled: false, snapToDataPoint: true },
//           labelFontColor: "#5a5a5a",
//           lineColor: "#5a5a5a",
//           tickColor: "yellow",
//           labelFormatter: function (e) {
//             const date = new Date(e.value);
//             const year = date.getFullYear();
//             const month = date.toLocaleString('default', { month: 'short' });

//             if (['5h', '1j'].includes(selectedInterval)) {
//               return `Jour ${e.value} - Mois ${month} ${year}`;
//             } else if (['1w', '1m'].includes(selectedInterval)) {
//               return `Jour ${e.value} - Mois ${month} ${year}`;
//             } else if (selectedInterval === '1y') {
//               return `${month} ${year}`;
//             } else if (selectedInterval === '5y') {
//               return (date.getMonth() % 2 === 0) ? `${year} - ${month}` : '';
//             } else if (['10y', '25y'].includes(selectedInterval)) {
//               return year;
//             }
//             return '';
//           }
//         },
//         axisY: {
//           prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent",
//           labelFormatter: function () {
//             return "";
//           }
//         },
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render();
//   };

//   const API_URL = 'http://localhost:8000/api/';

//   const fetchData = async (interval) => {
//     setLoading(true);
//     setDps1([]); // Réinitialiser les données lors du chargement
//     let url = `${API_URL}getDataFromLastPeriodbougiesvertuel/${marcheId}/${leagueid}/${interval}`;

//     try {
//       const response = await fetch(url);
//       const data = await response.json();
//       console.log('ddd',data)

//       if (data.error) {
//         console.error("Erreur API :", data.error);
//         setLoading(false);
//         return;
//       }

//       let dataPoints = [];

//       if (['5h', '1j'].includes(interval)) {
//         dataPoints = data.values.map(item => ({
//           x: item.nbjourVirtuel,
//           y: [Number(item.open), Number(item.high), Number(item.low), Number(item.close)],
//           color: item.color,
//           label: ` ${item.nbjourVirtuel} -  ${item.mois_virtuel} -  ${item.annee_virtuelle}`
//         }));
//       } else if (['1w', '1m'].includes(interval)) {
//         dataPoints = data.values.map(item => ({
//           x: item.nbjourVirtuel,
//           y: [Number(item.open), Number(item.high), Number(item.low), Number(item.close)],
//           color: item.color,
//           label: ` ${item.nbjourVirtuel} -  ${item.mois_virtuel} -  ${item.annee_virtuelle}`
//         }));
//       } else {
//         dataPoints = data.values.flatMap(yearData =>
//           yearData.mois_values.map(monthData => ({
//             x: new Date(yearData.annee_virtuelle, monthData.mois - 1),
//             y: [Number(monthData.open), Number(monthData.high), Number(monthData.low), Number(monthData.close)],
//             color: monthData.color,
//           }))
//         );
//       }

//       setDps1(dataPoints);
//       updateChart(dataPoints);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints;
//       chart.render();
//     }
//   };

//   const handleIntervalSelect = (interval) => {
//     setSelectedInterval(interval);
//     fetchData(interval); // Charger les nouvelles données pour la période sélectionnée
//   };

//   return (
//     <div>
//       {loading && <p>Chargement des données...</p>}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         {Object.keys(intervals).map(interval => (
//           <button
//             key={interval}
//             style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
//             onClick={() => handleIntervalSelect(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// const buttonStyle = {
//   padding: '5px 10px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d',
//   color: 'white',
// };

// export default StockChart;
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';
const StockChart = ({ marcheId, leagueid }) => {
  const [dps1, setDps1] = useState([]);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('1m');  // Intervalle par défaut

  const intervals = {
    '5h': '5h',
    '1j': '1j',
    '1w': '1w',
    '1m': '1m',
    '1y': '1y',
    '5y': '5y',
    '10y': '10y',
    '25y': '25y',
  };

  useEffect(() => {

    // Initialiser le graphique une fois
    initializeChart();
    // Charger les données par défaut une fois le graphique initialisé
    fetchData(selectedInterval);
  }, [marcheId, leagueid]); // Appeler uniquement si marcheId ou leagueid change
  useEffect(() => {
    // Charger les données par défaut après l'initialisation du graphique
    if (chart) {
      fetchData(selectedInterval);
    }
  }, [chart, selectedInterval]); // Déclenché après que le graphique soit prêt et l'intervalle soit sélectionné

  const initializeChart = () => {
    const stockChart = new window.CanvasJS.StockChart("chartContainer", {
      theme: "light2",
      exportEnabled: false,
      navigator: { enabled: false },
      rangeSelector: { inputFields: { enabled: false }, enabled: false },
      backgroundColor: "",
      charts: [{
        axisX: {
          crosshair: { enabled: false, snapToDataPoint: true },
          labelFontColor: "#5a5a5a",
          lineColor: "#5a5a5a",
          tickColor: "yellow",
       
          labelFormatter: function (e) {
            const date = new Date(e.value);
        
            if (['5h', '1j', '1w', '1m'].includes(selectedInterval)) {
                const moisVirtuel = (date.getMonth() + 1).toString().padStart(2, '0'); // Numéro du mois avec deux chiffres
               const anneeVirtuelle = date.getFullYear();
            const jourVirtuel = date.getDate();

                return `${jourVirtuel}/${moisVirtuel}/${anneeVirtuelle}`; // Affiche uniquement le numéro du mois
            }
        
            if (selectedInterval === '1y') {
                return (date.getMonth() + 1).toString().padStart(2, '0'); // Affiche uniquement le numéro du mois
            }
        
            if (['5y', '10y', '25y'].includes(selectedInterval)) {
                return date.getFullYear().toString(); // Affiche uniquement l'année
            }
        
            return '';
        }
        
        },
        axisY: {
          labelFontColor: "#5a5a5a",
          gridThickness: 0,
          tickLength: 0,
          lineThickness: 0,
          labelFormatter: function () { return ''; }
        },
        data: [{
          type: "candlestick",
          yValueFormatString: "$#,###.##",
          dataPoints: dps1,
          risingColor: "#00ff00",  // Couleur des bougies montantes
          fallingColor: "#ff0000", // Couleur des bougies descendantes
          borderColor: "yellow",    // Couleur de la bordure des bougies
          // borderThickness: 220,      // Épaisseur de la bordure des bougies
        }]
      }]
    });

    setChart(stockChart);
    stockChart.render();
  };

  const API_URL = 'https://tradingseries.fr/admin/public/api/';
  // const API_URL = 'http://localhost:8000/api/';

  const fetchData = async (interval) => {
    setLoading(true);
    let url = `${API_URL}getDataFromLastPeriodbougiesvertuel/${marcheId}/${leagueid}/${interval}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      // console.log('Data fetched:', data);

      if (data.error) {
        console.error("Erreur API :", data.error);
        setLoading(false);
        return;
      }

      const anneeVirtuelle = data.annee_virtuelle;
      let dataPoints;

      if (['5h', '1j', '1w', '1m'].includes(interval)) {
        dataPoints = data.values.map(item => ({
          x: new Date(anneeVirtuelle, item.mois_virtuel - 1, item.jour_dans_mois_virtuel),
          y: [Number(item.open), Number(item.high), Number(item.low), Number(item.close)],
          color: item.color,
        }));
      } else if (interval === '1y') {
        dataPoints = data.values[0].mois_values.map(monthData => ({
          x: new Date(anneeVirtuelle, monthData.mois - 1, 1),
          y: [Number(monthData.open), Number(monthData.high), Number(monthData.low), Number(monthData.close)],
          color: monthData.color,
        }));
      } else {
        dataPoints = data.values.flatMap(yearData =>
          yearData.mois_values.map(monthData => ({
            x: new Date(yearData.annee_virtuelle, monthData.mois - 1),
            y: [Number(monthData.open), Number(monthData.high), Number(monthData.low), Number(monthData.close)],
            color: monthData.color,
          }))
        );
      }

      setDps1(dataPoints);
      updateChart(dataPoints);  // Met à jour le graphique avec les données récupérées
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  const updateChart = (dataPoints) => {
    if (chart) {
      chart.options.charts[0].data[0].dataPoints = dataPoints;
      chart.render();
    }
  };

  const handleIntervalSelect = (interval) => {
    setSelectedInterval(interval);
    fetchData(interval);  // Charger les nouvelles données pour l'intervalle sélectionné
  };

  return (
    <div>
      {loading && <p>Chargement des données...</p>}
      <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
      <div style={{ marginTop: '30px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        {Object.keys(intervals).map(interval => (
          <button
            key={interval}
            style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
            onClick={() => handleIntervalSelect(interval)}
          >
            {interval.toUpperCase()}
          </button>
        ))}
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: '5px 1px',
  backgroundColor: '#252f3d',
  color: 'gray',
  borderRadius: '5px',
  margin: '5px',
  border: 'none',
};

const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#252f3d',
  color: 'white',
};

export default StockChart;
