// ToDoListModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';

function ToDoListModal({ isOpen, onClose, onAdd }) {
  const [texts, setTexts] = useState([]);
  const [tempText, setTempText] = useState('');

  const closeModal = () => {
    onClose();
    setTempText('');
  };

  const handleChange = (e) => {
    setTempText(e.target.value);
  };

  const handleAddText = () => {
    if (tempText.trim() !== '') {
      setTexts([...texts, tempText]);
      setTempText('');
    }
  };

  const handleDeleteText = (index) => {
    const newTexts = [...texts];
    newTexts.splice(index, 1);
    setTexts(newTexts);
  };

  const handleConfirm = () => {
    setTexts([...texts, tempText]);
    setTempText('');
    onAdd([...texts, tempText]);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Ajouter un texte"
    >
      <h2>Ajouter un texte</h2>
      <div>
        <input
          type="text"
          value={tempText}
          onChange={handleChange}
          placeholder="Entrez le texte"
        />
        <button onClick={handleAddText}>Ajouter</button>
      </div>
      <div>
        {texts.map((text, index) => (
          <div key={index}>
            <span>{text}</span>
            <button onClick={() => handleDeleteText(index)}>X</button>
          </div>
        ))}
      </div>
      <button onClick={handleConfirm}>Valider</button>
      <button onClick={closeModal}>Annuler</button>
    </Modal>
  );
}

export default ToDoListModal;
