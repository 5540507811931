/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
// import React, { useState, useEffect } from 'react';
// import ReactFlagsSelect from "react-flags-select";

// import bitcoin from '../../images/bitcoin.png'

// import USD from '../../images/USD.png'
// import Ethereum from '../../images/Ethereumimg.png';
// import { Link } from "react-router-dom";
// import ProgressiveLineChart from "../../components/chartgraphique/progressiveLineChart";
// import Carousel from "../../components/Carousel";

// import { getMarches, getDataForCurrentMinutes, postAchat, postVente, getImagesByMarketType, getMarchesByType, getAvailableCountriesByType } from '../../Api/authApi.js';
// import axios from 'axios'; // Import Axios
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import { useParams } from 'react-router-dom';

// import { useLocation } from 'react-router-dom';



// const PortefeuilleMenu = () => {
//   const [selectedTime, setSelectedTime] = useState('1m');
//   const timeOptions = [
//     { key: '5h', label: '5h' },
//     { key: '1j', label: '1 jour' },
//     { key: '1w', label: '1 semaine' },
//     { key: '1m', label: '1 mois' },
//     { key: '1y', label: '1 an' },
//     { key: '5y', label: '5 ans' },
//     { key: '10y', label: '10 ans' },
//     { key: '25y', label: '25 ans' }
//   ];
//   const navigate = useNavigate(); // Ajouter cette ligne
//   const [selectedMarche, setSelectedMarche] = useState(null);
//   const userId = useSelector((state) => state.auth.user.id);

//   const selectedMarketredux = useSelector((state) => state.market.selectedMarket);

//   const marketToDisplay = selectedMarketredux || selectedMarche;


//   const handleSelectChange = (event) => {
//     setSelectedTime(event.target.value);
//   };

//   const cardData = [
//     { id: 1, img: Ethereum, title: 'Titre 1', value: 'Valeur 1' },
//     { id: 2, img: USD, title: 'Titre 2', value: 'Valeur 2' },
//     { id: 3, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
//     { id: 4, img: bitcoin, title: 'Titre 1', value: 'Valeur 1' },
//     { id: 5, img: USD, title: 'Titre 2', value: 'Valeur 2' },
//     { id: 6, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
//   ];
//   const [startIndex, setStartIndex] = useState(0);



//   const [marches, setMarches] = useState([]);
//   const [marchesid, setmarchesid] = useState(0);
//   const [leagueid, setleagueid] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [marketData, setMarketData] = useState({}); // Ajouter l'état pour les données du marché
//   const [marketImages, setMarketImages] = useState({});
//   const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
//   const [countries, setCountries] = useState([]);
//   const location = useLocation();
//   const market = location.state?.market; // Récupérer les données du marché


//   useEffect(() => {
//     if (selectedMarketredux) {
//       // Si un marché est sélectionné dans Redux, utiliser celui-ci
//       setmarchesid(selectedMarketredux.marche_id);
//       setleagueid(selectedMarketredux.league_id); // Tu peux aussi récupérer league_id si nécessaire
//       setSelectedMarche(selectedMarketredux); // Mettre à jour le marché sélectionné avec celui de Redux
//     } else if (marches.length > 0) {
//       setSelectedMarche(marches[0]);
//       setmarchesid(marches[0].marche_id);
//       setleagueid(marches[0].league_id);
//     }
//   }, [selectedMarketredux, marches]);


//   useEffect(() => {
//     const fetchMarches = async () => {
//       try {
//         setLoading(true); // Démarrer le chargement
//         const data = await getMarches(userId); // Appel à l'API pour récupérer les marchés
//         setMarches(data); // Stocker les marchés récupérés
//         if (data.length > 0) {
//           setSelectedMarche(data[0]); // Sélectionner le premier marché par défaut
//           setmarchesid(data[0].marche_id); // Initialiser l'ID du premier marché
//           setleagueid(data[0].league_id); // Initialiser l'ID de la ligue du premier marché
//         }
//       } catch (err) {
//         setError(err.message); // Gérer les erreurs
//       } finally {
//         setLoading(false); // Arrêter le chargement
//       }
//     };

//     if (userId) {
//       fetchMarches(); // Lancer la récupération des marchés si userId est disponible
//     }
//   }, [userId]);


//   useEffect(() => {
//     const fetchMarches = async () => {
//       try {
//         setLoading(true);
//         const data = await getMarches(userId);
//         setMarches(data);

//         if (data.length > 0) {
//           // Récupérer les données supplémentaires pour le premier marché
//           const firstMarket = data[0];
//           const enrichedData = await getDataForCurrentMinutes(firstMarket.marche_id, firstMarket.league_id);

//           setSelectedMarche({
//             ...firstMarket,
//             current_value: enrichedData.value,
//             last_value: enrichedData.last_value,
//             difference: enrichedData.difference,
//             heure_complete: enrichedData.heure_complete,
//           });

//           setmarchesid(firstMarket.marche_id);
//           setleagueid(firstMarket.league_id);
//         }
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (userId) {
//       fetchMarches();
//     }
//   }, [userId]);


//   useEffect(() => {
//     const fetchMarketType = async () => {
//       try {
//         const response = await getImagesByMarketType();
//         setMarketImages(response);
//         // console.log(response)
//       } catch (err) {
//         setError(err.message);
//       }
//     };

//     fetchMarketType();
//   }, []);

//   useEffect(() => {
//     const fetchMarkets = async () => {
//       try {
//         const markets = await getMarchesByType('Actions');
//         // console.log(markets);
//       } catch (error) {
//         console.error(error.message);
//       }
//     };

//     fetchMarkets();
//   }, []);

//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.auth.user);


//   const handleAchat = async () => {
//     // Récupérer les données à partir de marketToDisplay
//     const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = marketToDisplay;

//     // Récupérer l'ID de l'utilisateur
//     const userId = user.id;

//     // Arrondir la valeur actuelle à deux décimales
//     let value = parseFloat(current_value).toFixed(2);

//     try {
//       // Effectuer l'achat en envoyant les données
//       const response = await postAchat(userId, marche_id, value, type, league_id);
//       // console.log('Achat effectué avec succès:', response);

//       // Redirection après l'achat
//       navigate('/portefeuille_achat', {
//         state: {
//           marche_id,
//           titre,
//           icon,
//           value,
//           hour: heure_complete,
//           difference,
//           type,
//           league_id
//         }
//       });
//     } catch (error) {
//       console.error('Erreur lors de l\'achat:', error);
//       // alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
//     }
//   };

//   const handleVente = async () => {
//     // Récupérer les données à partir de marketToDisplay
//     const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = marketToDisplay;

//     // Récupérer l'ID de l'utilisateur
//     const userId = user.id;

//     // Arrondir la valeur actuelle à deux décimales
//     let value = parseFloat(current_value).toFixed(2);

//     try {
//       // Effectuer la vente en envoyant les données (remplacer postAchat par postVente si tu as une fonction dédiée)
//       const response = await postVente(userId, marche_id, value, type, league_id);
//       console.log('Vente effectuée avec succès:', response);

//       // Redirection après la vente
//       navigate('/portefeuille_vente', {
//         state: {
//           marche_id,
//           titre,
//           icon,
//           value,
//           hour: heure_complete,
//           difference,
//           type,
//           league_id
//         }
//       });
//     } catch (error) {
//       console.error('Erreur lors de la vente:', error);
//       // alert('Une erreur est survenue lors de la vente. Veuillez réessayer.');
//     }
//   };




//   return (
//     <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-14 pt-6">

//       <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
//         <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-500 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col border-b  ">
//           <div className=" flex  justify-between pt-2">
//             <p className="flex justify-start text-left font-sans font-bold px-2 text-md " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Marchés financiers</p>
//             <Link  to={`/Favorites_marchés`} className='p-2'>
//               <button className=" bg-[#252f3d] text-white flex text-xs  border-gray-500 border gap-x-4 pr-6 pl-4 items-center ">
//                 <span className="">
//                   <svg class="w-4 h-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
//                     <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
//                   </svg>

//                 </span>
//                 <span className=" font-medium text-xs lg:text-sm">Favoris</span>
//                 {/* <span className=" font-medium text-xs lg:text-sm">{marchesid}</span> */}

//               </button></Link>
//           </div>



//           {marketToDisplay && (
//             <div className=" flex  justify-between gap-1 items-center py-4">
//               <div className='flex gap-2 flex-row justify-center text-center items-center'>
//                 <img
//                   src={`https://tradingseries.fr/admin/storage/app/public/${marketToDisplay.icon}`}
//                   className="w-14 h-14 bg-transparent rounded-full"
//                   alt={marketToDisplay.titre}
//                 />
//                 <div>
//                   <p className="text-[12px] font-semibold text-justify">{marketToDisplay.titre}</p>
//                   <p className="text-[11px] text-gray-400 font-medium">{marketToDisplay.type}</p>
//                 </div>
//               </div>
//               <div className="flex gap-2">
//                 <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
//                   ACHAT
//                 </button>
//                 <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
//                   VENTE
//                 </button>
//               </div>
//             </div>
//           )}
//           {/* V2 */}
//           <div className="w-[100%]">
//             <ProgressiveLineChart
//               selectedTime={selectedTime}
//               leagueid={leagueid} marcheId={marchesid} />


//           </div>


//           <div className=" flex  justify-between px-3 gap-1 items-center lg:py-4">
//             <div className="w-auto">

//               <select
//                 id="time-select"
//                 value={selectedTime}
//                 // disabled={isWeekend} // Désactive le select si c'est le week-end

//                 onChange={handleSelectChange}
//                 className="mt-1 block w-full pl-3 py-2 border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm text-xs rounded-md text-white bg-[#252f3d]"
//               >
//                 {timeOptions.map((time, index) => (
//                   <option key={index} value={time.key}>
//                     {time.label}
//                   </option>
//                 ))}
//               </select>
//               {/* {isWeekend && <p></p>} */}

//             </div>
//             <Link to={`/portefeuille_detail/${marchesid}/${leagueid}`} className="text-[11px] font-medium lg:text-sm "><span class="slot-bYDQcOkp"><span role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"></path></svg></span></span></Link>

//           </div>


//         </div>
//         <div className="lg:w-[98%] w-[98%] px-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-center mx-auto lg:py-4   flex-col  ">


//           <div className="lg:w-[98%] w-[98%]  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-[#252f3d] text-white flex justify-center mx-auto lg:py-4 flex-col">
//             <div className="w-auto flex justify-between py-4">
//               <Link to="/portefeuille_actions" className="flex items-center text-[13px] font-medium">

//               </Link>
//               <div className="relative">
//                 <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                   <svg
//                     className="w-3 h-3 text-gray-300 "
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 20 20"
//                   >
//                     <path
//                       stroke="currentColor"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth={2}
//                       d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                     />
//                   </svg>
//                 </div>
//                 <input
//                   type="search"
//                   id="default-search"
//                   className="block  w-40 ps-10 text-sm text-gray-300 border border-gray-400 rounded-xs bg-[#252f3d] "
//                   placeholder="Recherche"
//                   required=""
//                 />

//               </div>

//             </div>
//             {Object.entries(marketImages).map(([marketType, images]) => (
//               <div key={marketType} className="">
//                 <div className="w-auto flex items-center py-1 mt-2">
//                   <span>
//                     <svg className="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
//                       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
//                     </svg>
//                   </span>

//                   {/* Utilisez Link pour passer le type via l'URL */}
//                   <Link
//                     to={`/portefeuille_actions/${marketType}`}
//                     className="cursor-pointer text-sm font-semibold"
//                     style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}
//                   >
//                     {marketType}
//                   </Link>
//                 </div>

//                 <Link to={`/portefeuille_actions/${marketType}`} className="cursor-pointer text-sm font-semibold">
//                   <Carousel images={images.map(image => `https://tradingseries.fr/admin/storage/app/public/${image.image_path}`)} />
//                 </Link>
//               </div>
//             ))}

//           </div>

//         </div>
//       </div>

//     </div>

//   )
// };
// export default PortefeuilleMenu;
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveLineChart from "../../components/chartgraphique/progressiveLineChart";
import { getMarches, getDataForCurrentMinutes, postAchat, postVente, getImagesByMarketType } from '../../Api/authApi.js';
import Carousel from "../../components/Carousel";
import { FaStar } from "react-icons/fa"; // Importer l'icône étoile pleine

const PortefeuilleMenu = () => {
  const [selectedTime, setSelectedTime] = useState('1m');
  const timeOptions = [
    { key: '5h', label: '5h' },
    { key: '1j', label: '1 jour' },
    { key: '1w', label: '1 semaine' },
    { key: '1m', label: '1 mois' },
    { key: '1y', label: '1 an' },
    { key: '5y', label: '5 ans' },
    { key: '10y', label: '10 ans' },
    { key: '25y', label: '25 ans' }
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.id);
  const location = useLocation(); // Récupérer `location` pour accéder aux données de `state`

  const [selectedMarche, setSelectedMarche] = useState(null);
  const [marches, setMarches] = useState([]);
  const [marchesid, setmarchesid] = useState(0);
  const [leagueid, setleagueid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [marketImages, setMarketImages] = useState({});

  // Récupérer les favoris depuis Redux
  const favoriteMarkets = useSelector((state) => state.market.favoriteMarkets);
  const isFavorite = favoriteMarkets.some(fav => fav.marche_id === selectedMarche?.marche_id);


  useEffect(() => {
    const fetchMarches = async () => {
      try {
        setLoading(true);
        const data = await getMarches(userId);
        setMarches(data);

        let selectedMarket;

        // 1. Utiliser le favori passé via `location.state` s'il existe
        if (favoriteMarkets.length > 0) {
          // Sélectionner le premier favori
          selectedMarket = favoriteMarkets[0];
        } else if (location.state?.selectedFavorite) {
          // 2. Si `favoriteMarkets` est vide, vérifier `location.state.selectedFavorite`
          selectedMarket = location.state.selectedFavorite;
        } else {
          // Si aucun favori n'est trouvé, afficher un message d'erreur
          setError("Aucun marché favori disponible. Veuillez sélectionner un marché.");
        }

        // Si un marché sélectionné est défini, charger les données enrichies
        if (selectedMarket) {
          const enrichedData = await getDataForCurrentMinutes(selectedMarket.marche_id, selectedMarket.league_id);
          // console.log("Enriched Data:", enrichedData);

          setSelectedMarche({
            ...selectedMarket,
            current_value: enrichedData.value,
            last_value: enrichedData.last_value,
            difference: enrichedData.difference,
            heure_complete: enrichedData.hour ,
          });

          setmarchesid(selectedMarket.marche_id);
          setleagueid(selectedMarket.league_id);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchMarches();
    }
  }, [userId, location.state]);


  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedTime(event.target.value);
  };

  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);
 
  useEffect(() => {
    const fetchMarches = async () => {
      setLoading(true);
      try {
        // Récupérer les marchés de l'utilisateur
        const data = await getMarches(userId);
        setMarches(data);

        let selectedMarket;

        // 1. Vérifier si un marché favori est passé via `location.state`
        if (location.state?.selectedFavorite) {
          selectedMarket = location.state.selectedFavorite;
        } else {
          // 2. Rechercher les marchés favoris dans `data`
          const favoriteMarkets = data.filter(market => market.is_favorite && market.marche_id && market.league_id);

          if (favoriteMarkets.length > 0) {
            // Si des favoris existent, sélectionner le premier favori
            selectedMarket = favoriteMarkets[0];
          }
        }

        // Si aucun marché favori n'est trouvé, afficher un message
        if (!selectedMarket) {
          setError("Pas de marché favori disponible. Veuillez sélectionner un marché.");
        } else {
          // Sinon, charger les données enrichies pour le marché favori sélectionné
          const enrichedData = await getDataForCurrentMinutes(selectedMarket.marche_id, selectedMarket.league_id);

          setSelectedMarche({
            ...selectedMarket,
            current_value: enrichedData.value,
            last_value: enrichedData.last_value,
            difference: enrichedData.difference,
            heure_complete: enrichedData.heure_complete,
          });

          setmarchesid(selectedMarket.marche_id);
          setleagueid(selectedMarket.league_id);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchMarches();
    }
  }, [userId, location.state]);


  const handleAchat = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postAchat(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_achat', {
        state: { marche_id, titre, icon, value,  heure_complete, difference, type, league_id }
      });
    } catch (error) {
      console.error('Erreur lors de l\'achat:', error);
    }
  };

  const handleVente = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postVente(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_vente', {
        state: { marche_id, titre, icon, value, heure_complete, difference, type, league_id }
      });
    } catch (error) {
      console.error('Erreur lors de la vente:', error);
    }
  };

  return (
    <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-14 pt-6">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-500 lg:rounded-lg sm:border md:border xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto py-1 lg:py-4 flex-col border-b">
          <div className="flex justify-between pt-2">
            <p className="flex justify-start text-left font-sans font-bold px-2 text-md" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Marchés financiers</p>
            <Link to={`/Favorites_marchés`} className='p-2'>
              <button className=" bg-[#252f3d] text-white flex text-xs  border-gray-500 border gap-x-4 pr-6 pl-4 items-center ">
                <span className="">
                  <svg class="w-4 h-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                  </svg>

                </span>
                <span className=" font-medium text-xs lg:text-sm">Favoris</span>
                {/* <span className=" font-medium text-xs lg:text-sm">{marchesid}</span> */}              </button></Link>
          </div>

          {/* {selectedMarche && (
            <div className="flex justify-between gap-1 items-center py-4">
              <div className="flex gap-2 flex-row justify-center text-center items-center">
                <div className="relative">

                  {isFavorite && (
                    <FaStar className="text-yellow-500 absolute -top-1 left-0" fontSize={22} />
                  )}
                  <img
                    src={`https://tradingseries.fr/admin/storage/app/public${selectedMarche.icon}`}
                    className="w-14 h-14 bg-transparent rounded-full"
                    alt={selectedMarche.titre}
                  />
                </div>
                <div>
                  <p className="text-[12px] font-semibold text-justify">{selectedMarche.titre}</p>
                  <p className="text-[11px] text-gray-400 font-medium">{selectedMarche.type}</p>
                </div>
              </div>
              <div className="flex gap-2">
                <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
                  ACHAT
                </button>
                <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
                  VENTE
                </button>
              </div>
            </div>
          )} */}
          {selectedMarche ? (
            <div className="flex justify-between gap-1 items-center py-4">
              <div className="flex gap-2 flex-row justify-center text-center items-center">
                <div className="relative">
                  {isFavorite && (
                    <FaStar className="text-yellow-500 absolute -top-1 left-0" fontSize={22} />
                  )}
                  <img
                    src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`}
                    className="w-14 h-14 bg-transparent rounded-full"
                    alt={selectedMarche.titre}
                  />
                </div>
                <div>
                  <p className="text-[14px] font-semibold text-justify">{selectedMarche.titre}</p>
                  {/* <p className="text-[11px] text-gray-400 font-medium">{selectedMarche.type}</p> */}
                </div>
              </div>
              <div className="flex gap-2">
                <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
                  ACHAT
                </button>
                <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
                  VENTE
                </button>
              </div>
            </div>
          ) : (
            <p className="text-center text-gray-500 font-medium py-4">
              Veuillez sélectionner un marché.
            </p>
          )}


          <div className="w-[100%]">
            <ProgressiveLineChart
              selectedTime={selectedTime}
              leagueid={leagueid} marcheId={marchesid} />
          </div>

          <div className="flex justify-between px-3 gap-1 items-center lg:py-4">
            <div className="w-auto">
              <select
                id="time-select"
                value={selectedTime}
                onChange={handleSelectChange}
                className="mt-1 block w-full pl-3 py-2 border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm text-xs rounded-md text-white bg-[#252f3d]"
              >
                {timeOptions.map((time, index) => (
                  <option key={index} value={time.key}>
                    {time.label}
                  </option>
                ))}
              </select>
            </div>
            {selectedMarche ? (<Link to={`/portefeuille_detail/${marchesid}/${leagueid}`} className="text-[11px] font-medium lg:text-sm ">
              <span className="slot-bYDQcOkp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                  <path fill="currentColor" d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"></path>
                </svg>
              </span>
            </Link>) : ''}
          </div>
        </div>
        <div className="lg:w-[98%] w-[98%] px-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-center mx-auto lg:py-4   flex-col  ">


          <div className="lg:w-[98%] w-[98%]  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-[#252f3d] text-white flex justify-center mx-auto lg:py-4 flex-col">
            <div className="w-auto flex justify-between py-4">
              <Link to="/portefeuille_actions" className="flex items-center text-[13px] font-medium">

              </Link>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-3 h-3 text-gray-300 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block  w-40 ps-10 text-sm text-gray-300 border border-gray-400 rounded-xs bg-[#252f3d] "
                  placeholder="Recherche"
                  required=""
                />

              </div>

            </div>
            {Object.entries(marketImages).map(([marketType, images]) => (
              <div key={marketType} className="">
                <div className="w-auto flex items-center py-1 mt-2">
                  <span>
                    <svg className="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                    </svg>
                  </span>

                  {/* Utilisez Link pour passer le type via l'URL */}
                  <Link
                    to={`/portefeuille_actions/${marketType}`}
                    className="cursor-pointer text-sm font-semibold"
                    style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}
                  >
                    {marketType}
                  </Link>
                </div>

                <Link to={`/portefeuille_actions/${marketType}`} className="cursor-pointer text-sm font-semibold">
                  <Carousel images={images.map(image => `https://tradingseries.fr/admin/storage/app/public/${image.image_path}`)} />
                </Link>
              </div>
            ))}

          </div>

        </div>
      </div>

    </div>


  );
};

export default PortefeuilleMenu;
