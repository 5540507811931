/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import { Link } from 'react-router-dom';


const AuthPage = () => {
  return (
    <section className='bg-[#252f3d]  py-9 text-white flex justify-center h-[100vh]'>
      <div className='flex flex-col space-y-1 '>
        <div className='flex  justify-center h-[230px] flex-col items-center'>
          <CustomHeader width="" />
        </div>
        <h1 className='flex justify-center text-center  font-bold font-sans text-[30px] pb-4 ' style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Trading Series</h1>
        {/* <h1 className='flex justify-center text-center  font-bold font-sans text-[30px] pb-4 ' style={{ fontFamily: 'Coolvetica, sans-serif', fontWeight: '600' ,fontSize:'30px'}}>Trading Series</h1> */}

        <div className='flex items-center flex-col space-y-6 justify-center mt-0'>
          <p className='font-sans font-bold text-center'  style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Connecte toi dès maintenant !</p>
          <Link to="/connexion" className=' flex justify-center w-[100%]'>
            <button
              type="button"
              className="py-2 lg:py-2.5 md:py-2.5 xl:py-2.5 2xl:py-2.5 text-base font-bold text-black flex justify-center bg-white focus:ring-4 2xl:w-[45%] w-[63%] rounded-sm text-center items-center "
            >
              <svg
                className="w-6 h-6 text-black "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>

            </button>
          </Link>
          <p className='font-sans font-bold text-md text-center'  style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Ou inscris toi pour débuter l'aventure!</p>
          <Link to="/inscription" className=' flex justify-center w-[100%]'>
            <button
              type="button"
              className="py-2 lg:py-3 md:py-2.5 xl:py-2.5 2xl:py-2.5 text-sm font-sans font-bold text-black items-center bg-white focus:ring-4 2xl:w-[35%] w-[60%] rounded-sm text-center"
              style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}
            >
              Pas encore de compte ?

            </button>
          </Link>
        </div>
        <div className='mt-7'>
          <CustomFooter styelefooter='text-[15px]  mx-1  font-bold ' />
        </div>
      </div>

    </section>
  )
}

export default AuthPage