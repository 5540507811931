/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { login } from '../../redux/authSlice';
import axios from 'axios';
import { funcAchat } from '../../Api/authApi.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoChevronUp } from "react-icons/go";
import { GoChevronDown } from "react-icons/go";

const PorteFeuilleAcaht = () => {
    const navigate = useNavigate(); // Ajouter cette ligne

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { state } = location;
    // const { id, titre, icon, value, date } = state;
    const { marche_id: market_id, titre, icon, difference, value: price, heure_complete, type, league_id: league_id } = state;



    useEffect(() => {
        return () => {
            console.log(state)
        };
    }, [])


    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    // États pour les champs du formulaire
    const [montant, setMontant] = useState('');
    const [leverage, setLeverage] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [takeProfit, setTakeProfit] = useState('');
    const handleAchat = async () => {
        // Vérifier que les champs sont remplis
        if (!montant || !leverage || !stopLoss || !takeProfit) {
            toast.warning('Veuillez remplir tous les champs');
            return;
        }

        const data = {
            user_id: user.id,
            market_id: market_id,
            league_id: league_id,
            montant: parseFloat(montant),
            price: parseFloat(price),
            leverage: parseInt(leverage, 10),
            stop_loss: parseFloat(stopLoss),
            take_profit: parseFloat(takeProfit),
            difference: parseFloat(difference),
            type: type
        };

        try {
            // Appel de la fonction d'API pour effectuer l'achat
            const response = await funcAchat(data);

            // Suppression des valeurs des champs après l'achat
            setMontant('');
            setLeverage('');
            setStopLoss('');
            setTakeProfit('');

            // Afficher le message de succès retourné par le serveur
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.warning(response.message);
            }

            // Naviguer vers la page d'accueil
            navigate('/accueil');

        } catch (error) {
            console.error('Erreur lors de l\'ajout:', error.message); // Affiche le message d'erreur
            toast.warning(error.message); // Afficher le message d'erreur
        }
    };


    // const handleAchat = async () => {
    //     if (!user) {
    //         console.log('Utilisateur non connecté');
    //         return;
    //     }

    //     // Vérifier que les champs sont remplis
    //     if (!montant || !leverage || !stopLoss || !takeProfit) {

    //         toast.warning('Veuillez remplir tous les champs');
    //         return;
    //     }

    //     const data = {
    //         user_id: user.id,
    //         market_id: market_id, // Utilisez 'market_id' depuis state
    //         league_id: league_id, // Utilisez 'market_id' depuis state
    //         montant: parseFloat(montant),
    //         price: parseFloat(price), // Utilisez 'price' depuis state
    //         leverage: parseInt(leverage, 10), // Assurez-vous que leverage est bien un entier
    //         stop_loss: parseFloat(stopLoss),
    //         take_profit: parseFloat(takeProfit),
    //         difference: parseFloat(difference),
    //         type: type // Inclure 'type' dans les données envoyées

    //     };
    //     try {
    //         // const response = await axios.post('https://tradingseries.fr/admin/public/api/handleAchat', data);
    //         const response = await funcAchat(data);  // Utilisez la fonction importée


    //         // console.log(response);
    //         setMontant('');
    //         setLeverage('');
    //         setStopLoss('');
    //         setTakeProfit('');
    //         toast.success(response.message);

    //         navigate('/accueil');

    //     } catch (error) {
    //         console.error('Erreur lors de l\'ajout du portefeuille:', error);
    //         toast.warning(error);

    //     }
    // };

    return (
        <div className=" bg-[#252f3d] w-[100%]  h-[100%]      relative overflow-auto py-4">
            <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
                <div className="lg:w-[50%] w-[98%] p-2 lg:border border-gray-400 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col">


                    <div className="flex gap-4 justify-center items-center p-6">
                        <div className="relative">

                            <img src={`https://tradingseries.fr/admin/storage/app/public/${icon}`} className=" w-20 h-20 lg:w-28 lg:h-28  md:w-24 md:h-24 xl:w-28 xl:h-28 2xl:w-28 2xl:h-28 rounded-full" alt="Logo principal" />

                        </div>
                        <div classNam1e="flex flex-col gap-1">
                            <div className="flex gap-3 flex-col">
                                <p className=" text-sm text-gray-200 font-semibold">{titre}</p>
                                <p className=" text-sm text-gray-200 font-semibold">{type}</p>
                                <p className=" text-sm text-gray-200 font-semibold">{price} €</p>

                            </div>
                            <div className="flex  gap-2  text-[13px]">
                                {/* <p className="text-[#97271d] font-semibold  text-sm">{parseFloat(difference).toFixed(2)}</p> */}

                                <p className={`flex p-1 ${difference < 0 ? 'text-red-500' : 'text-[#678f2c]'}`}>
                                    {difference ? (
                                        <>
                                            {difference < 0 ? '' : '+'} {/* Affiche "+" pour les valeurs positives */}
                                            {`${!isNaN(parseFloat(difference)) ? parseFloat(difference).toFixed(2) : '0.00'}`}
                                            {difference < 0 ? (
                                                <span>
                                                    <GoChevronDown fontSize={18} color="red" /> {/* Flèche vers le bas pour les valeurs négatives */}
                                                </span>
                                            ) : (
                                                <span>
                                                    <GoChevronUp fontSize={18} color="#678f2c" /> {/* Flèche vers le haut pour les valeurs positives */}
                                                </span>
                                            )}

                                        </>
                                    ) : '...'}
                                </p>



                            </div>
                            <div className="flex items-center  gap-2">
                                <p>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#97271d" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                        </svg>
                                    </span>
                                </p>
                                <p className=" text-xs text-gray-300 font-semibold">{
                                    heure_complete
                                }</p>

                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col gap-3 mt-3">
                        <div className="flex flex-col gap-3 justify-center items-center">

                            <input
                                type="text"
                                className=" border rounded-sm text-black w-[90%] py-2 flex pl-4 gap-8 bg-white font-medium text-[14px]  px-5"
                                placeholder="Montant"

                                value={montant}
                                onChange={(e) => setMontant(e.target.value)}

                            />


                            <select
                                className=" border rounded-sm text-white w-[90%] py-2 flex pl-4 gap-8 bg-[#252f3d] font-medium text-[14px]  px-5 border-gray-400"
                                value={leverage}
                                onChange={(e) => setLeverage(e.target.value)}

                            >
                                {/* 'leverage' => 'required|numeric|in:1,2,5,10,20,30', */}

                                <option value="" disabled defaultValue>Choisissez un effet de levier</option>
                                <option value="1">X1</option>
                                <option value="2">X2</option>
                                <option value="5">X5</option>
                                <option value="10">X10</option>
                            </select>
                            <div className=" border rounded-sm  w-[90%]  flex pl-3 gap-7 bg-[#252f3d] font-medium text-[14px]   border-gray-400 justify-between">
                                <span className="text-[#97271d] py-2 justify-start">Stop loss</span>
                                <input
                                    type="text"
                                    className="bg-[#252f3d]  border border-white py-2 text-white  w-[30%] text-end pr-5"

                                    placeholder="59,5€"
                                    value={stopLoss}
                                    onChange={(e) => setStopLoss(e.target.value)}
                                />
                            </div>
                            <div className=" border rounded-sm  w-[90%]  flex pl-3 gap-7 bg-[#252f3d] font-medium text-[14px]   border-gray-400 justify-between">
                                <span className="text-[#678f2c] py-2 justify-start">  Take Profit</span>
                                <input
                                    type="text"
                                    className="bg-[#252f3d]  border border-white py-2 text-white  w-[30%] text-end pr-5"

                                    placeholder="61 €"
                                    value={takeProfit}
                                    onChange={(e) => setTakeProfit(e.target.value)}
                                />
                            </div>


                        </div>
                        <p className="lg:text-[12px] text-[11px]  text-center  font-medium">*Nous te conseillons de toujours placer un stop loss</p>

                    </div>
                    <div className="flex justify-center items-center py-3">
                        <button className="py-2 px-4 w-[30%] flex justify-center items-center  text-sm  bg-[#678f2c] rounded-sm text-white  font-medium" onClick={handleAchat}
                        >ACHAT</button>
                    </div>



                </div>

            </div>


        </div>

    )
};
export default PorteFeuilleAcaht;
