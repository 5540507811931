

// export default Accueil;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import clockset from '../../images/clockset.png'
import many from '../../images/many.png'
import params from '../../images/params.png'
import { useDispatch, useSelector } from 'react-redux';
import replay from '../../images/replay.png'
import Drawer from "../../components/Drawer";
import { Link, useNavigate } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import datablog from "../../components/datablog";
import start from '../../images/start.png'
import more from '../../images/more.png'
import good from '../../images/good.png'
import { funcGetTransactionsGroupedByMarket, getAllPendingTransactions, getArticles,getDatabaydatevirtuel, getVideos, getDefis, getArticleById, addLike, addRate, addDislike, addComment, getPortefeuilleData, getAllTransactions, checktransactionsPourDefiActif, getMarches, closeTransaction, getCurrentVirtualData } from '../../Api/authApi.js';
import { useParams } from 'react-router-dom';
import { FaRegThumbsUp, FaRegStar, FaThumbsUp, FaStar } from 'react-icons/fa'; // Importation des icônes
import { CgMoreO } from "react-icons/cg";
import { GoChevronUp } from "react-icons/go";
import { GoChevronDown } from "react-icons/go";

import { toast, ToastContainer } from 'react-toastify';


const Accueil = () => {


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [portefeuille, setPortefeuille] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [portefeuilledata, setportefeuilledata] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [defis, setDefis] = useState([]);
    const [articles, setArticles] = useState([]);
    const icons = [good, start, more];
    const user = useSelector((state) => state.auth.user);
    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [starClicked, setStarClicked] = useState(false);
    const [newComment, setNewComment] = useState(''); // Nouvel état pour le commentaire
    const userId = useSelector((state) => state.auth.user.id);
    const [comments, setComments] = useState([]); // Nouvel état pour stocker les commentaires
    const [article, setArticle] = useState({});
    const [defischeck, setdefischeck] = useState([]);
    const [remainingdays, setremainingdays] = useState([]);
    const [selectedMarche, setSelectedMarche] = useState(null);
    const [marches, setMarches] = useState([]);
    // const [marchesid, setmarchesid] = useState();
    // const [leagueid, setleagueid] = useState(0);
    const [marketData, setMarketData] = useState({}); // Ajouter l'état pour 
    const [currentGameDate, setCurrentGameDate] = useState('');
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [marchesid, setMarchesid] = useState();
    const [leagueid, setLeagueid] = useState();
    const [dateJeu, setDateJeu] = useState(null);
    // Utiliser `user.id` comme dépendance pour que l'effet se mette à jour si l'utilisateur change
    // console.log('dateJeu',dateJeu)

    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };
    const handleLikeClick = async (id) => {
        try {
            const response = await addLike(id, userId);
            setLikeClicked(true);
            setDislikeClicked(false); // Désélectionner dislike
            // console.log(response.data)

        } catch (err) {
            setError(err.message);
        }
    };

    const handleStarClick = async (id) => {
        try {
            const response = await addRate(id, userId);
            setStarClicked(true);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };
    const handleCommentSubmit = async (id) => {
        try {
            const response = await addComment(id, userId, newComment);
            // Mettez à jour l'article avec le nouveau commentaire
            // Ajouter le nouveau commentaire à la liste des commentaires
            setComments([...comments, response.data.newComment]);
            // Réinitialisez le champ de commentaire
            setNewComment('');

            setArticle(response.data);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };

    const fetchPendingTransactions = async () => {
        try {
            const data = await getAllPendingTransactions(user.id);
            setTransactions(data.transactions_pending); // Mettre à jour les transactions avec les achats et ventes en attente
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchPendingTransactions(); // Appeler la fonction une fois lors du premier rendu
        const intervalId = setInterval(() => {
            fetchPendingTransactions();
        }, 180000); // 3 minutes = 180000 ms

        return () => clearInterval(intervalId);
    }, [user]);

    const handleTransactionClick = async (uniqueId, currentValue) => {
        try {
            const response = await closeTransaction(uniqueId, currentValue);
            toast.success('Transaction fermée avec succès');

            // Mise à jour des transactions immédiatement après la fermeture
            await fetchPendingTransactions(); // Appel direct à fetchPendingTransactions
        } catch (error) {
            console.error('Erreur lors de la fermeture de la transaction :', error);
            setError('Erreur lors de la fermeture de la transaction.');
        }
    };

    useEffect(() => {
        const checkDefis = async () => {
            try {
                const response = await checktransactionsPourDefiActif(userId);
                // console.log('defischeck', response.defis_disponibles)

                setremainingdays(response.jours_restants)
                setdefischeck(response.defis_disponibles); // Mettre à jour les défis


            } catch (error) {
                // console.error('Erreur lors de la vérification des défis', error);
            }

        };
        // Appel initial de la fonction
        checkDefis();

    }, []);

    useEffect(() => {
        const fetchDefis = async () => {
            try {
                const response = await getDefis(userId);
                setDefis(response.defis); // Assurez-vous que votre API retourne les défis dans une clé `defis`
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDefis();
    }, []);
    useEffect(() => {
        const fetchMarches = async () => {
            try {
                setLoading(true);
                const data = await getMarches(userId);
                setMarches(data);
                // console.log('datamarcher',data)
                if (data.length > 0) {
                    const firstMarche = data[1];
                    setSelectedMarche(firstMarche);
                    setMarchesid(firstMarche.marche_id);
                    setLeagueid(firstMarche.league_id);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchMarches();
        }
    }, [userId]);
    // console.log('marchesid',marchesid,leagueid)

    // useEffect(() => {
    //     if (marchesid && leagueid) {
    //         const fetchdate = async () => {
    //             try {
    //                 const response = await getCurrentVirtualData(marchesid, leagueid);
    //                 // console.log('Réponse reçue de l’API:', response); // Confirmez la structure de la réponse ici
    //                 setDateJeu(response);
    //             } catch (err) {
    //                 console.error("Erreur dans fetchdate:", err.message);
    //                 setError(err.message);
    //             }
    //         };

    //         fetchdate();
    //         const interval = setInterval(fetchdate, 300000); // 5 minutes
    //         return () => clearInterval(interval);
    //     }
    // }, [marchesid, leagueid]);
    useEffect(() => {
        const period = "5h"; // Set period default value here
    
        if (marchesid && leagueid) {
          const fetchDate = async () => {
            try {
              const response = await getDatabaydatevirtuel(marchesid, leagueid, period);
            //   console.log('Réponse reçue de l’API:', response);
              setDateJeu(response);
            } catch (err) {
              console.error("Erreur dans fetchDate:", err.message);
              setError(err.message);
            }
          };
    
          fetchDate();
          const interval = setInterval(fetchDate, 300000); // 5 minutes
          return () => clearInterval(interval);
        }
      }, [marchesid, leagueid]);


    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const articlesData = await getArticles();
                setArticles(articlesData);
                // console.log('articlesData', articlesData)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchArticles();
    }, []);
    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            try {
                const data = await getPortefeuilleData(user.id);
                setportefeuilledata(data);
                // console.log('getPortefeuilleData', data)

            } catch (error) {
                setError(error.message);
            }

        };

        fetchPortefeuilleData();
    }, [user]);

    const monthNames = [
         "Janv", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"
    ];


    useEffect(() => {
        const fetchMarches = async () => {
            try {
                setLoading(true); // Assurez-vous que le chargement commence
                const data = await getMarches(userId); // Appel à l'API avec le user_id
                // console.log('marchés', data);

                setMarches(data);
                if (data.length > 0) {
                    setSelectedMarche(data[0]); // Sélectionnez le premier marché

                    // Vérifier la date pour le premier marché
                    const currentDate = getCurrentGameDate(data[0].date_start, data[0].date_end);
                    setCurrentGameDate(currentDate); // Mettre à jour la date du jeu (ou vide si hors intervalle)
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchMarches();
        }
    }, [userId]);
    const getCurrentGameDate = (dateStart, dateEnd) => {
        const startDate = new Date(dateStart);
        const endDate = new Date(dateEnd);
        const now = new Date();

        // Vérifier si la date actuelle est entre la date de début et la date de fin
        if (now >= startDate && now <= endDate) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Format de date
            return now.toLocaleDateString('fr-FR', options); // Renvoie la date formatée si elle est dans l'intervalle
        }
        return ''; // Retourne vide si la date actuelle n'est pas dans l'intervalle
    };
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };
    function formatMontant(montant) {
        return parseFloat(montant)
            .toFixed(2) // Toujours 2 décimales
            .replace(/\d(?=(\d{3})+\.)/g, '$& ') // Séparation des milliers avec un espace
            .replace('.', ','); // Remplace le point par une virgule pour les décimales
    }


    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12 pt-5">
            <div className=" w-full  bg-[#252f3d] ">
                <div className="grid  lg:grid-cols-2  md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-3  lg:gap-7 sm:px-10 md:px-16">
                    <div className="lg:w-[98%] w-[100%] px-1 lg:border md:border-2 sm:border-2  lg:border-gray-200 sm:border-gray-200 md:border-gray-200  md:rounded-md sm:rounded-md    border-gray-600 lg:rounded-lg shadow bg-balck text-white flex justify-between mx-auto border-b py-1 lg:p-[18px]">
                        <div className="w-[100%] flex flex-col gap-4">
                            <div className="w-[100%] flex ">
                                <div className=" w-[53%] space-y-1  py-1  ">
                                    <p className="font-sans font-bolder  lg:text-[20px] pl-3  text-[17px] " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Mon portefeuille
                                    </p>
                                    <div className="flex items-center justify-between pl-2  ">
                                        <p className="lg:text-[25px] font-bold text-[18px]" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '800' }}>
                                            {/* {portefeuille.montant_total} € */}
                                            {portefeuilledata.montant_total !== undefined
                                                ? formatMontant(portefeuilledata.montant_total) + ' €'
                                                : ''}
                                        </p>
                                        {/* <div className="flex flex-col gap-1 justify-center">
                                          
                                            <span className={`lg:text-[20px] flex flex-col font-bold text-[11px]  ${portefeuilledata && portefeuilledata.difference_percentage < 0 ? 'text-red-500' : 'text-[#678f2c]'}`}>
                                                {portefeuilledata ? `${!isNaN(parseFloat(portefeuilledata.difference_percentage)) ? parseFloat(portefeuilledata.difference_percentage).toFixed(2) : '0.00'}%` : '...'}

                                            </span>

                                        </div> */}
                                        <div className="flex flex-col gap-1 justify-center">
                                            <span className={`lg:text-[20px] flex flex-col items-center gap-1 font-semibold text-[12px] ${portefeuilledata && portefeuilledata.difference_percentage < 0 ? 'text-red-500' : 'text-[#678f2c]'}`}>
                                                {portefeuilledata ? (
                                                    <>
                                                        {portefeuilledata.difference_percentage < 0 ? (
                                                            <span><GoChevronDown fontSize={18} color="red" />
                                                            </span> // Flèche vers le bas pour les valeurs négatives
                                                        ) : (
                                                            <span><GoChevronUp fontSize={18} color="#678f2c" />
                                                            </span> // Flèche vers le haut pour les valeurs positives
                                                        )}
                                                        {`${!isNaN(parseFloat(portefeuilledata.difference_percentage)) ? parseFloat(portefeuilledata.difference_percentage).toFixed(2) : '0.00'}%`}
                                                    </>
                                                ) : '...'}
                                            </span>
                                        </div>

                                    </div>
                                    <div>
                                        
                                        <p className="lg:text-[25px] font-semibold text-[17px] pl-2" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>
                                            {/* {dateJeu && (
                                                <span>
                                                    {dateJeu.jour_dans_mois_virtuel} {monthNames[dateJeu.mois_virtuel]} {dateJeu.annee_virtuelle}
                                                </span>
                                            )} */}
                                            {dateJeu && dateJeu.values && dateJeu.values[0] && (
  <span>
    {dateJeu.values[0].jour_dans_mois_virtuel} {monthNames[dateJeu.values[0].mois_virtuel - 1]} {dateJeu.annee_virtuelle}
  </span>
)}
                                        </p>

                                    </div>

                                </div>
                                <div className=" w-[47%] ml-1  border-l border-gray-500  space-y-2  ">
                                    <p className=" font-sans font-bolder  lg:text-[20px] lg:px-6 items-center gap-2 text-[15px] flex pl-3 pt-1.5 " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Vos positions
                                        <span><svg class="w-5 h-5 text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                                        </svg>
                                        </span>
                                    </p>


                                    {transactions && transactions.slice(0, 2).map((item) => (
                                        <div key={item.unique_id} className="lg:border lg:border-gray-500 mb-4 flex justify-around items-center w-auto pl-2 ">
                                            <span className="p-1 font-medium text-gray-900">
                                                {/* <img src={`http://127.0.0.1:8000${item.market_icon}`} className="w-8 h-6" alt="Logo" /> */}
                                                <img src={`https://tradingseries.fr/admin/storage/app/public/${item.market_icon}`} className="w-8 h-6" alt="Logo" />
                                            </span>

                                            <span className="font-normal text-[12px] flex flex-row" style={{ color: item.gain_perte_color, fontFamily: 'DM Sans' }}>
                                                {item.gain_perte_latent > 0 ? `+${Number(item.gain_perte_latent).toFixed(2)}` : `${Number(item.gain_perte_latent).toFixed(2)}`} €
                                                <span className="flex justify-center items-center pl-0.5">
                                                    {item.gain_perte_latent < 0 ? (
                                                        <span><GoChevronDown fontSize={18} color="red" /></span> // Flèche vers le bas pour les valeurs négatives
                                                    ) : (
                                                        <span><GoChevronUp fontSize={18} color="#678f2c" /></span> // Flèche vers le haut pour les valeurs positives
                                                    )}
                                                </span>
                                            </span>

                                            <span className="py-1" onClick={() => handleTransactionClick(item.unique_id, item.current_value)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </span>
                                        </div>
                                    ))}

                                    <Link to={'/PorteFeuille'} className=" flex text-[11px] justify-end">  (et plus...)
                                    </Link>
                                </div>
                            </div>
                            <div className="pb-3">
                                <div className="w-[100%] flex  lg:flex-row flex-col items-center justify-center  ">
                                    <Link to='/portefeuille_menu' className=" bg-white text-black text-center w-[98%]  p-2 rounded-sm  font-sans font-bold text-[14px]">   <button >Investir</button></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="lg:w-[98%] w-[98%] lg:p-4 p-2 lg:border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-between mx-auto lg:border md:border-2 sm:border-2   sm:border-gray-200 md:border-gray-200  md:rounded-md sm:rounded-md    border-gray-500 py-2 pb-3 border-b ">
                        <div className="w-[100%] flex flex-col gap-2">
                            <p className=" font-sans font-bold  lg:text-md text-[13px] pb-4" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Participe aux défis pour prendre une longueur d'avance sur le classement !
                            </p>


                            {defischeck.map((item) => (
                                <div className="flex justify-between gap-3 py-1" key={item.id}>
                                    <div className="flex gap-3">
                                        <img
                                            // src={`http://127.0.0.1:8000/storage/${item.icon}`}
                                            src={`https://tradingseries.fr/admin/storage/app/public/${item.icon}`}
                                            alt={item.titre_defis}
                                            className="object-scale-down lg:w-12 lg:h-12 h-7 w-7"
                                        />
                                        <span>
                                            <p className="text-[13px] lg:text-[16px] " style={{ fontFamily: 'DM Sans' }}>{item.titre}</p>
                                            <span className="text-[#6ca82c] lg:text-[14px] text-[13px]" style={{ fontFamily: 'DM Sans' }}>+  {item.montant_gagner}€</span>
                                        </span>
                                    </div>
                                    <span>
                                        <svg
                                            className={`w-6 h-6 ${item.defi_atteint ? 'text-green-500' : 'text-white'}`}  // Classe conditionnelle
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            ))}


                            <div className="flex gap-2">
                                <img src={replay} className="object-scale-down w-4 h-4" />

                                <p className="text-[13px] lg:text-[13px] text-gray-300" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Il reste {remainingdays} jours avant l'actualisation des défis
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-2  ">
                    <div className="lg:mx-12 px-4 md:px-4 sm:p-10 md:p-16 py-2">
                        <p className="text-white lg:text-lg font-semibold py-3 text-[14px] " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Découvre la finance et
                            l’au-delà ici !</p>
                        {articles.length === 0 ? (
                            <p>Aucun article disponible.</p>
                        ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 sm:gap-5 md:gap-5 lg:gap-5 xl:gap-5 2xl:gap-5 gap-1 ">
                                {articles.map((item) => (
                                    <div key={item.id}

                                        className="overflow-hidden shadow-lg bg-[#252f3d] sm:border-2 md:border-2 lg:border-2 xl:border-2 2xl:border-2 sm:rounded-md md:rounded-md lg:rounded-md xl:rounded-md 2xl:rounded-md">
                                        <Link to={`/article/${item.id}`} className="relative">
                                            {/* <img className="w-[100%] lg:[w-100%]  h-60 " src={`https://tradingseries.fr/admin/storage/app/public/${item.image}`} alt={item.title} /> */}
                                            <img
                                                className="w-full h-auto lg:w-full lg:h-[1440px] object-cover"
                                                style={{ maxWidth: '2560px', maxHeight: '1440px' }}
                                                src={`https://tradingseries.fr/admin/storage/app/public/${item.image}`}
                                                alt={item.title}
                                            />

                                        </Link>

                                        <div className="px-2 py-2">
                                            <p className="text-[11px] lg:text-[15px] text-white  font-medium">{item.title}</p>
                                            <span className="flex justify-end gap-1">
                                                {/* {icons.map((icon, iconIndex) => (
                                                    <span
                                                        key={iconIndex}
                                                        className={`icon-class`}
                                                    >
                                                        <img key={iconIndex} className="w-auto h-auto" src={icon} alt="Icon" />
                                                    </span>
                                                ))} */}
                                                <button onClick={() => handleLikeClick(item.id)}>
                                                    {likeClicked ? <FaThumbsUp className="text-white text-xl" /> : <FaRegThumbsUp className="text-white text-xl" />}
                                                </button>



                                                <Link className=" items-center flex "
                                                    to={`/article/${item.id}`}>
                                                    <button >
                                                        {dislikeClicked ? <CgMoreO className="text-white text-xl" /> : <CgMoreO className="text-white text-xl" />}
                                                    </button>
                                                </Link>

                                                {/* <button onClick={handleStarClick(item.id)}> */}
                                                <button onClick={() => handleStarClick(item.id)}>
                                                    {starClicked ? <FaStar className="text-white text-xl" /> : <FaRegStar className="text-white text-xl" />}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </div>


            </div>

        </div>



    )
};
export default Accueil;
