import React from 'react'
import logotrading from '../../images/logotrading.png'

const CustomHeader = ({ width }) => {
  return (
    <div className='' style={{display: 'contents'}}>
      
      <img class={`w-[23rem] h-auto lg:${width}`} src={logotrading} alt="logotrading" />

    </div>
  )
}

export default CustomHeader