import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFavoriteMarket } from '../../redux/marketSlice'; // Importer l'action de suppression
import { FaStar } from "react-icons/fa"; // Icône étoile pleine
import { useNavigate } from 'react-router-dom'; // Importer useNavigate

const FavorisPage = () => {
    const favoriteMarkets = useSelector((state) => state.market.favoriteMarkets); // Accéder aux marchés favoris depuis Redux
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Utiliser navigate pour la redirection

    // Fonction pour supprimer un marché des favoris
    const handleRemoveFavorite = (market) => {
        dispatch(removeFavoriteMarket(market));
        navigate('/portefeuille_menu');

    };
    const handleMarketClick = (market) => {
        navigate('/portefeuille_menu', { state: { selectedFavorite: market } });
    };
    const getChangeColor = (difference) => {
        return difference > 0 ? 'text-green-500' : 'text-red-500';
    };
    const calculatePercentage = (difference, lastValue) => {
        if (!lastValue || lastValue == 0) return 0; // Éviter la division par zéro
        return ((difference / lastValue) * 100).toFixed(2); // Calculer le pourcentage
    };
    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
            <div className="grid lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-7 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[96%] lg:p-4 p-2 lg:border md:border sm:border rounded-lg shadow bg-[#252f3d] text-white flex mx-auto flex-col">
                    <h3>Marchés Favoris</h3>

                    {/* Afficher les marchés favoris */}
                    {favoriteMarkets.length > 0 ? (
                        <div>
                            
                            {favoriteMarkets.map((market) => (
                                <div 
                                  key={market.marche_id} 
                                  className="flex justify-between px-3 py-3 border-b border-gray-500"
                                  onClick={() => handleMarketClick(market)} // Redirection au clic

                                >
                                    <div className="flex gap-2 items-center">

                                        <div className="relative">
                                            {/* Icône de favori pour supprimer */}
                                            <FaStar
                                                fontSize={22}
                                                className="text-yellow-500 -top-1 absolute"
                                                onClick={() => handleRemoveFavorite(market)} // Supprime le marché des favoris
                                            />
                                            <img    src={`https://tradingseries.fr/admin/storage/app/public/${market.icon}`}className="w-14 h-14 rounded-full" alt={market.titre} />
                                            
                                        </div>
                                        <div className="flex flex-col">
                                        <p className='font-semibold'>{market.type}</p>

                                            <p className="text-[17px] lg:text-md font-bolder">{market.titre}</p>
                                            <p className="text-[12px] lg:text-md font-normal text-gray-300 flex gap-2 items-center ">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="red" className="bi bi-clock-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5.5 0 0 0 .496-.868L8 8.71z" />
                                                    </svg>
                                                </span>
                                                {market.heure_complete}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-col font-medium">
                                        <p className="text-[14px] lg:text-md ">{parseFloat(market.current_value).toFixed(3)}€</p>
                                        <p className={`text-[14px] lg:text-md ${getChangeColor(market.difference)}`}>
                                            {market.difference > 0 ? '+' : ''}{calculatePercentage(market.difference, market.last_value)}%
                                        </p>
                                    </div>
                                </div>
                                
                            ))}
                        </div>
                    ) : (
                        <p className="text-center py-5">Aucun marché favori disponible.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FavorisPage;
