// /* eslint-disable jsx-a11y/alt-text */
// /* eslint-disable jsx-a11y/img-redundant-alt */
// import React, { useState } from "react";
// import CustomHeader from '../../components/customheader'
// import CustomFooter from '../../components/customfooter'
// import CustomInput from '../../components/custominput'
// import key from '../../images/key.png'
// import unlock from '../../images/unlock.png'
// import CustomButton from '../../components/custombutton'
// import next from '../../images/next.png'
// import { Link } from 'react-router-dom';
// import { loginUser } from '../../Api/authApi.js'
// import { useNavigate } from "react-router-dom";
// import { toast } from 'react-toastify';
// import { login } from "../../redux/authSlice.js";
// import { useDispatch } from "react-redux";

// const LoginPage = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const [LoginData, setLoginData] = useState({
//     email: '',
//     password: '',
//   });


//   const handleChange = (e) => {
//     setLoginData({ ...LoginData, [e.target.name]: e.target.value });
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginUser(LoginData, dispatch);
//       // console.log('User logged in:', response.remember_token); // Afficher les données utilisateur récupérées
//       toast.success(response.message);

//       setLoginData({
//         email: '',
//         password: '',
//       });
//       navigate('/accueil');

//     } catch (error) {
//       toast.warning(error.response.data.message)

//     }
//   };
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import CustomHeader from '../../components/customheader';
import CustomFooter from '../../components/customfooter';
import CustomInput from '../../components/custominput';
import CustomButton from '../../components/custombutton';
import key from '../../images/key.png';
import unlock from '../../images/unlock.png';
import next from '../../images/next.png';
import { loginUser } from '../../Api/authApi.js';
import { login } from "../../redux/authSlice.js";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [LoginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(LoginData, dispatch);
      if (response && response.message) {
        toast.success(response.message);
        // console.log(response)
        setLoginData({
          email: '',
          password: '',
        });
        navigate('/accueil');
      } else {
        throw new Error('Réponse invalide du serveur');
      }
    } catch (error) {
      // console.log(error)

      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Une erreur est survenue';
      toast.warning(errorMessage);
      // console.log(errorMessage)

    }
  };

  return (
    <section className=' bg-[#252f3d] text-white relative h-[100vh]'>
      <Link to='/' className='absolute left '>
        <img class={`w-16 h-16 pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className='  py-9 text-white flex justify-center'>

        <div className='flex items-center flex-col space-y-3 '>
        <div className='flex  justify-center h-[220px] flex-col items-center'>
          <CustomHeader width="w-[50%] mt-9" />
        </div>
        <h1 className='flex justify-center text-center  font-bold font-sans text-[30px] pb-4 '  style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Trading Series</h1>
        

          <div className='items-center flex-col space-y-4  w-[60%] sm:w-[50%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
            <form className=" gap-3 flex flex-col" onSubmit={handleSubmit}>

              <CustomInput
                styleinput="pl-14 font-medium  rounded-sm  lg:py-3 "
                icon={<svg
                  className="w-5 h-5 text-black "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 16"
                >
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>}
                placeholder='Adresse e-mail'
                type="email" name="email" value={LoginData.email} onChange={handleChange}
              />
              <CustomInput
                styleinput="pl-14 py-1.5   lg:py-3  rounded-sm font-medium "

                icon={<img src={key} class=" w-6 h-6 placeholder-red-300" />}
                placeholder='Mot de passe'
                type="password"
                name="password" value={LoginData.password} onChange={handleChange}
              />
              <CustomButton
                icon={<img src={unlock} class=" w-6 h-5" />}
                text='S&rsquo;identifier'
                styleinput="bg-gray-300  mt-2 lg:py-3 font-semibold text-sm gap-6 py-2.5 rounded-sm"

              />
            </form>
          </div>
          <div className='items-center flex-col space-y-4 pt-1 w-[60%] sm:w-[50%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] font-sans font-bold'>
            {/* <Link to='/accueil'> */}
            {/* </Link> */}
            <Link to='/mot-de-passe-oublie' className='flex justify-end'>
              <p className='text-left lg:text-[17px]  text-[15px] font-sans '   style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>
                Mot de passe oublié ?
              </p>
            </Link>
          </div>

          <div className='mt-7'>
            <CustomFooter  styelefooter='text-[15px]  mx-1  lg:py-3  font-bold' />
          </div>

        </div>

      </div>
    </section>

  )
}

export default LoginPage