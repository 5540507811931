// import React, { useEffect } from 'react';
// import Pusher from 'pusher-js';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const VideoNotification = () => {
//     useEffect(() => {
//         // Initialiser Pusher avec la clé API et le cluster corrects
//         const pusher = new Pusher('277b974b332c672e42b1', {
//             cluster: 'eu',
//             encrypted: true
//         });
//         console.log('Connexion à Pusher...');

//         // S'abonner au canal 'video-channel'
//         const channel = pusher.subscribe('video-channel');
//         // Confirmation de l'abonnement réussi
//         channel.bind('pusher:subscription_succeeded', () => {
//             console.log('Abonné avec succès au canal video-channel.');
//         });
//         // Écouter l'événement 'video-available'
//         channel.bind('video-available', function(data) {
//             console.log('Événement video-available reçu :', data);
//             if (data.video) {
//                 // Affiche la notification si une vidéo est disponible
//                 toast.info(`Nouvelle vidéo disponible : ${data.video.title}`, {
//                     position: "top-right",
//                     autoClose: 5000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//             }
//             pusher.connection.bind('error', function(err) {
//                 console.error('Erreur de connexion Pusher :', err);
//                 toast.error('Erreur de connexion à Pusher. Veuillez réessayer plus tard.', {
//                   position: "top-right",
//                   autoClose: 5000,
//                   hideProgressBar: false,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                   draggable: true,
//                 });
//               });
              
          
//         });
        

//         // Gestion des erreurs de connexion
//         pusher.connection.bind('error', function (err) {
//             console.error('Erreur de connexion Pusher :', err);
//         });

//         // Nettoyage lors du démontage du composant
//         return () => {
//             pusher.unsubscribe('video-channel');
//             console.log('Désabonné du canal video-channel.');
//         };
//     }, []);

//     return (
//         <div>
//             <h1>Notifications Vidéos</h1>

//             <ToastContainer /> {/* Conteneur pour les notifications */}
//         </div>
//     );
// };

// export default VideoNotification;
// import { useEffect } from 'react';
// import Pusher from 'pusher-js';

// function VideoNotification() {
//     useEffect(() => {
//         const pusher = new Pusher('277b974b332c672e42b1', {
//             cluster: 'eu',
//             encrypted: true
//         });

//         const channel = pusher.subscribe('video-channel');
//         channel.bind('video-available', function(data) {
//             console.log('Nouvelle vidéo disponible :', data.title);
//             alert(`Nouvelle vidéo disponible : ${data.title}`);
//         });

//         return () => {
//             pusher.unsubscribe('video-channel');
//         };
//     }, []);

//     return (
//         <div>
//             <h1>Notifications de Vidéos</h1>
//             <p>Attendez les notifications de disponibilité des vidéos.</p>
//         </div>
//     );}

// export default VideoNotification;
  // useEffect(() => {
    //     // Initialisez Pusher et Laravel Echo
    //     Pusher.logToConsole = true;

       
    //     window.Echo = new Echo({
    //         broadcaster: 'pusher',
    //         key: '277b974b332c672e42b1',  // Clé Pusher
    //         cluster: 'eu',
    //         forceTLS: true,  // Utilisez TLS pour sécuriser la connexion
    //     });
    //     console.log('window.Echo:', window.Echo);  // Vérifiez si "window.Echo" est bien défini

    //     // Écoutez le canal "video-available"
    //     const channel = window.Echo.channel('video-available');

    //     channel.listen('.video.available', (event) => {
    //         console.log('Événement reçu:', event);  // Log de l'événement dans la console
    //         if (event.available) {
    //             setMessage(event.message);  // Met à jour le message
    //             console.log('toastId.current avant l\'utilisation:', toastId.current);

    //             // Afficher la notification "toast"
    //             if (!toast.isActive(toastId.current)) {
    //                 // Si aucune notification n'est active, en afficher une
    //                 toastId.current = toast.success('Nouvelle vidéo disponible : ' + event.message);
    //             }            } else {
    //             setMessage('Aucune vidéo disponible pour le moment.');
    //         }
    //     });

    //     // return () => {
    //     //     if (window.Echo) {
    //     //         window.Echo.leave('video-available');
    //     //     }
    //     // };
    //     return () => {
    //         if (window.Echo && window.Echo.leave) {
    //             console.log('window.Echo lors du nettoyage:', window.Echo);  // Vérifiez si "window.Echo" existe au moment du nettoyage

    //             window.Echo.leave('video-available');
    //         }
    //     };
        
    // }, []);

// import React, { useEffect, useState } from 'react';
// import Pusher from 'pusher-js';

// function VideoNotification() {
//     const [message, setMessage] = useState('');

//     useEffect(() => {
//         const pusher = new Pusher('277b974b332c672e42b1', {
//             cluster: 'eu',
//             encrypted: true
//         });
//         const channel = pusher.subscribe('video-available');
//         channel.bind('video.available', function (data) {
//           console.log('Événement reçu:', data);  // Vérifie si l'événement est bien reçu
//           setMessage(data.message);
//           alert(data.message);
//         });
      
//         channel.bind('pusher:subscription_succeeded', function() {
//           console.log('Abonnement réussi');  // Vérifier si l'abonnement est réussi
//         });
      
//         channel.bind('pusher:subscription_error', function(status) {
//           console.error('Erreur d\'abonnement:', status);  // Déboguer si l'abonnement échoue
//         });
      
//         return () => {
//           channel.unbind_all();
//           channel.unsubscribe();
//         };
//       }, []);

//     return (
//         <div>
//       <h1>Notification Vidéo</h1>
//       <p>{message}</p>  {/* Affiche le message reçu dans l'interface */}
//     </div>
//     );}

// export default VideoNotification;
// verion coorect 
// import React, { useEffect, useState } from 'react';
// import Pusher from 'pusher-js';  // Assurez-vous que vous importez bien Pusher depuis 'pusher-js'
// import Echo from 'laravel-echo';
// import 'react-toastify/dist/ReactToastify.css';  // Importer le style de toast
// import { ToastContainer, toast } from 'react-toastify';  // Importer react-toastify
// import Modal from 'react-modal';  // Utilisation d'une bibliothèque pour la modale
// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//     },
// };

// function VideoNotification() {
//     const [message, setMessage] = useState('');
//     const toastId = React.useRef(null);  // Initialisez la référence à null
//     const [video, setVideo] = useState(null);  // Pour stocker les détails de la vidéo
//     const [available, setAvailable] = useState(false);  
//     const [modalIsOpen, setModalIsOpen] = useState(false);  // Gérer l'ouverture de la modale

  
//     useEffect(() => {
//         try {
//             // Pusher.logToConsole = true;
    
//             window.Echo = new Echo({
//                 broadcaster: 'pusher',
//                 key: '277b974b332c672e42b1',  // Clé Pusher
//                 cluster: 'eu',
//                 forceTLS: true,  // Utilisez TLS pour sécuriser la connexion
//             });
    
//             console.log('window.Echo:', window.Echo);
    
//             const channel = window.Echo.channel('video-available');
    
//             channel.listen('.video.available', (event) => {
            
//                 console.log('Événement reçu:', event);
//                 if (event.available) {
//                     setVideo(event.message.title);
//                     setAvailable(event.available);
//                     setMessage(event.message);
//                     if (!toast.isActive(toastId.current)) {
//                         toastId.current = toast.success('Nouvelle vidéo disponible : ' + event.message.title);
//                     }
//                 } else {
//                     setMessage('Aucune vidéo disponible pour le moment.');
//                 }
//             });
    
//         } catch (error) {
//             console.error('Erreur capturée:', error);
//         }
    
//         return () => {
//             if (window.Echo && window.Echo.leave) {
//                 window.Echo.leave('video-available');
//             }
//         };
//     }, []);
    
//     return (
//         <div>
//    {video && (
//                 <div>
//                     <h2>{video.title}</h2>
//                 </div>
//             )}            {/* <ToastContainer />  Conteneur pour afficher les toasts */}

//         </div>
//     );
// }

// export default VideoNotification;
import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { IoMdCloseCircle } from "react-icons/io";

function VideoNotification() {
    const [message, setMessage] = useState('');
    const toastId = React.useRef(null);
    const [video, setVideo] = useState(null);  // Stocker les détails de la vidéo
    const [available, setAvailable] = useState(false);  // Pour savoir si une vidéo est disponible
    const [isPlaying, setIsPlaying] = useState(false);  // Gérer l'état de lecture de la vidéo
    const [modal, setModal] = useState(false);  // Gérer l'état du modal
    const [videoLoading, setVideoLoading] = useState(true);  // État pour le chargement de la vidéo
    useEffect(() => {
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: '277b974b332c672e42b1',  // Clé Pusher
                cluster: 'eu',
                forceTLS: true,
            });

            const channel = window.Echo.channel('video-available');

            channel.listen('.video.available', (event) => {
                if (event.available) {
                    setVideo(event.message);  // Stocker la vidéo dans l'état
    
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(
                            <div className=' flex justify-around items-center'>
                                 {event.message.title}
                                <button 
                                    onClick={() => openModal()} 
                                    style={{ marginLeft: '10px', padding: '5px', backgroundColor: '#252f3d', color: 'white', border: 'none', cursor: 'pointer' }}
                                >
                                    Voir
                                </button>
                            </div>,
                            {
                                autoClose: false,
                                closeOnClick: false,
                                // position: toast.POSITION.TOP_RIGHT,
                            }
                        );
                    }
                }
            });

            return () => {
                if (window.Echo && window.Echo.leave) {
                    window.Echo.leave('video-available');
                }
            };
        }, []);

        const openModal = () => {
            setModal(!modal);  // Ouvrir ou fermer le modal
        };
    
        const spinner = () => {
            setVideoLoading(false);  // Stopper le spinner une fois la vidéo chargée
        };
    return (
        <div>
         
            {modal && (
                <section className="modal__bg">
                    <div className="modal__align">
                        <div className="modal__content" modal={modal}>
                            <IoMdCloseCircle
                                className="modal__close"
                                arial-label="Close modal"
                                onClick={openModal}
                            />
                            <div className="modal__video-align">
                             
                                <iframe
                                    className="modal__video-style"
                                    onLoad={spinner}
                                    loading="lazy"
                                    width="100%"
                                    height="600"
                                    src={`https://tradingseries.fr/admin/storage/app/public/${video.video_path}`}                                    title=" "
                                    // src={`http://127.0.0.1:8000/storage/${video.video_path}`}                                    title=" "
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

export default VideoNotification;
