// src/data.js
import betblog from '../../images/betblog.png'
import bitcoin from '../../images/bitcoin.png'
import clockset from '../../images/clockset.png'
import imgblogs from '../../images/imgblogs.png'
import analyse from '../../images/analyse.png'
import manyblog from '../../images/manyblog.png'
import start from '../../images/start.png'
import more from '../../images/more.png'
import good from '../../images/good.png'
import or from '../../images/icons/or.png'
import tradingblog from '../../images/tradingblog.png'
import Actionimg from '../../images/icons/Actionsicon.png'
import imgmany from '../../images/icons/imgmany.png'
import dislike from '../../images/icons/dislike.png'
import likeicon from '../../images/icons/likeicon.png'

const datablog = [
    {
        id: 1,
        imgSrc: betblog,
        title: "Sur quels marchés financiers est-il possible de trader ?",
        icons: [good, start, more],
        content: ` 
        <br/>
        <h1 style="display: flex;
        column-gap: 4px;">
        <img  src="${Actionimg}" style="width:20px;height:auto;"/>
        Actions</h1>
        <br/>
        <p>Une action est essentiellement une part de propriété dans une entreprise. Si vous possédez des actions d'une entreprise, vous êtes propriétaire d'une petite partie de cette entreprise. Les entreprises émettent des actions pour collecter de l'argent, et en échange, les détenteurs d'actions (actionnaires) peuvent recevoir des bénéfices sous forme de dividendes. Parfois, les détenteurs d'actions ont également le droit de voter sur certaines décisions importantes de l'entreprise lors des réunions.</p>
        <br/>
        <br/>

        <p>Par exemple, si vous avez des actions d'entreprises comme Apple, Amazon ou Coca-Cola, cela signifie que vous êtes propriétaire d'une partie de ces entreprises. Vous pourriez recevoir des dividendes si ces entreprises réalisent des bénéfices, et vous pourriez avoir le droit de voter sur des questions importantes les concernant. Les actions sont échangées sur des marchés boursiers où leur valeur peut fluctuer en fonction de divers facteurs.</p>
        <h1> Matières premières</h1>
        <p>Le marché des matières premières en bourse concerne la négociation de produits de base tels que l'or, le pétrole, les métaux industriels et les produits agricoles. Ces matières premières sont essentielles à l'économie mondiale et leurs prix varient en fonction de facteurs tels que l'offre, la demande, les conditions climatiques et les événements géopolitiques.</p>
        <br/>
        <br/>

        <p>Par exemple, l'or est souvent recherché comme une valeur refuge en période d'incertitude économique, ce qui peut entraîner une hausse de son prix. Le pétrole, en tant que principal combustible mondial, voit son prix fluctuer en fonction des décisions des pays producteurs et des variations de la demande mondiale.</p>
        <p>Les métaux industriels comme le cuivre et l'aluminium sont utilisés dans la fabrication et la construction, et leurs prix sont influencés par la croissance économique et les politiques commerciales. Les matières premières agricoles, telles que le blé et le maïs, dépendent des conditions climatiques et des tendances alimentaires mondiales.</p>
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${bitcoin}" style="width:20px;height:auto;"/>
        Crypto-monnaies</h1>
        <br/>
        <p>Le marché des cryptomonnaies englobe des actifs numériques utilisant la technologie blockchain, opérant indépendamment des autorités traditionnelles. Un exemple majeur est le Bitcoin (BTC), créé en 2009 par Satoshi Nakamoto. Il est reconnu comme la première et la plus importante cryptomonnaie en termes de capitalisation boursière.</h2>
        <br/>
        <p>D'autres cryptomonnaies, appelées altcoins, comprennent Ethereum (ETH), qui offre des contrats intelligents, et Ripple (XRP), conçu pour les transferts d'argent internationaux. Chaque cryptomonnaie a des utilisations spécifiques, telles que moyen de paiement ou exécution de contrats programmables.</p>

        

        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${imgmany}" style="width:20px;height:auto;"/>
        Devises</h1>

        <p>Le marché des devises, également connu sous le nom de Forex, est le plus grand marché financier mondial où les monnaies nationales sont échangées. Banques, institutions financières, entreprises internationales et traders individuels participent à ce marché, achetant et vendant des devises pour des raisons telles que le commerce international, l'investissement et la spéculation. Les taux de change, qui représentent la valeur relative des devises, fluctuent en raison de divers facteurs tels que l'offre et la demande, les indicateurs économiques, les taux d'intérêt et les événements géopolitiques. Les paires de devises, comme EUR/USD, sont utilisées pour indiquer la valeur d'une devise par rapport à une autre. Les fluctuations des taux de change offrent des opportunités de profit pour les participants du marché des devises</p>

   
        <br/>
         <h1 style="display: flex;
         column-gap: 8px;">
         <img  src="${or}" style="width:20px;height:auto;"/>Bonus:les ETFs et les obligations</h1>
         <br/>

        <p>Les ETF, ou fonds négociés en bourse, sont des instruments d'investissement qui permettent aux investisseurs d'acheter et de vendre des paniers diversifiés d'actifs sur les marchés financiers. Ils suivent généralement la performance d'un indice spécifique, offrant une diversification facile et transparente. Par exemple, l'ETF SPDR S&P 500 (SPY) suit les 500 plus grandes entreprises américaines, tandis que l'ETF iShares Core U.S. Aggregate Bond (AGG) offre une exposition aux obligations américaines diversifiées. Les ETF sont appréciés pour leur simplicité, leur liquidité et leur facilité d'accès, devenant ainsi des outils populaires dans le monde de l'investissement.</p>
        <br/>
        <br/>

        <p>Le marché des obligations est un secteur financier où les gouvernements et les entreprises empruntent de l'argent aux investisseurs en émettant des obligations. Ces obligations représentent des prêts que les investisseurs accordent à l'émetteur, qui s'engage à rembourser le montant initial avec des intérêts sur une période spécifiée. Les exemples incluent les obligations gouvernementales, utilisées pour financer des projets publics, et les obligations d'entreprise, émises par des sociétés pour lever des fonds. Les investisseurs reçoivent des paiements d'intérêts réguliers pendant la durée de l'obligation et récupèrent leur investissement initial à l'échéance. Le marché des obligations joue un rôle crucial dans le financement des activités économiques et offre aux investisseurs un moyen de générer des revenus. Les rendements sont influencés par les taux d'intérêt et la qualité de crédit de l'émetteur.</p>

        `
    },
    {
        id: 2,
        imgSrc: manyblog,
        title: "Quelles sont les meilleurs marchés pour gagner de l'argent en trading ?",
        icons: [good, start, more],
        content: ` 
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${Actionimg}" style="width:20px;height:auto;"/>
        Actions</h1>
        <br/>
        <p>Une action est essentiellement une part de propriété dans une entreprise. Si vous possédez des actions d'une entreprise, vous êtes propriétaire d'une petite partie de cette entreprise. Les entreprises émettent des actions pour collecter de l'argent, et en échange, les détenteurs d'actions (actionnaires) peuvent recevoir des bénéfices sous forme de dividendes. Parfois, les détenteurs d'actions ont également le droit de voter sur certaines décisions importantes de l'entreprise lors des réunions.</p>
        <br/>
        <br/>

        <p>Par exemple, si vous avez des actions d'entreprises comme Apple, Amazon ou Coca-Cola, cela signifie que vous êtes propriétaire d'une partie de ces entreprises. Vous pourriez recevoir des dividendes si ces entreprises réalisent des bénéfices, et vous pourriez avoir le droit de voter sur des questions importantes les concernant. Les actions sont échangées sur des marchés boursiers où leur valeur peut fluctuer en fonction de divers facteurs.</p>
        <h1> Matières premières</h1>
        <p>Le marché des matières premières en bourse concerne la négociation de produits de base tels que l'or, le pétrole, les métaux industriels et les produits agricoles. Ces matières premières sont essentielles à l'économie mondiale et leurs prix varient en fonction de facteurs tels que l'offre, la demande, les conditions climatiques et les événements géopolitiques.</p>
        <br/>
        <br/>

        <p>Par exemple, l'or est souvent recherché comme une valeur refuge en période d'incertitude économique, ce qui peut entraîner une hausse de son prix. Le pétrole, en tant que principal combustible mondial, voit son prix fluctuer en fonction des décisions des pays producteurs et des variations de la demande mondiale.</p>
        <p>Les métaux industriels comme le cuivre et l'aluminium sont utilisés dans la fabrication et la construction, et leurs prix sont influencés par la croissance économique et les politiques commerciales. Les matières premières agricoles, telles que le blé et le maïs, dépendent des conditions climatiques et des tendances alimentaires mondiales.</p>
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${bitcoin}" style="width:20px;height:auto;"/>
        Crypto-monnaies</h1>
        <br/>
        <p>Le marché des cryptomonnaies englobe des actifs numériques utilisant la technologie blockchain, opérant indépendamment des autorités traditionnelles. Un exemple majeur est le Bitcoin (BTC), créé en 2009 par Satoshi Nakamoto. Il est reconnu comme la première et la plus importante cryptomonnaie en termes de capitalisation boursière.</h2>
        <br/>
        <p>D'autres cryptomonnaies, appelées altcoins, comprennent Ethereum (ETH), qui offre des contrats intelligents, et Ripple (XRP), conçu pour les transferts d'argent internationaux. Chaque cryptomonnaie a des utilisations spécifiques, telles que moyen de paiement ou exécution de contrats programmables.</p>

        

        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${imgmany}" style="width:20px;height:auto;"/>
        Devises</h1>

        <p>Le marché des devises, également connu sous le nom de Forex, est le plus grand marché financier mondial où les monnaies nationales sont échangées. Banques, institutions financières, entreprises internationales et traders individuels participent à ce marché, achetant et vendant des devises pour des raisons telles que le commerce international, l'investissement et la spéculation. Les taux de change, qui représentent la valeur relative des devises, fluctuent en raison de divers facteurs tels que l'offre et la demande, les indicateurs économiques, les taux d'intérêt et les événements géopolitiques. Les paires de devises, comme EUR/USD, sont utilisées pour indiquer la valeur d'une devise par rapport à une autre. Les fluctuations des taux de change offrent des opportunités de profit pour les participants du marché des devises</p>

   
        <br/>
         <h1 style="display: flex;
         column-gap: 8px;">
         <img  src="${or}" style="width:20px;height:auto;"/>Bonus:les ETFs et les obligations</h1>
         <br/>

        <p>Les ETF, ou fonds négociés en bourse, sont des instruments d'investissement qui permettent aux investisseurs d'acheter et de vendre des paniers diversifiés d'actifs sur les marchés financiers. Ils suivent généralement la performance d'un indice spécifique, offrant une diversification facile et transparente. Par exemple, l'ETF SPDR S&P 500 (SPY) suit les 500 plus grandes entreprises américaines, tandis que l'ETF iShares Core U.S. Aggregate Bond (AGG) offre une exposition aux obligations américaines diversifiées. Les ETF sont appréciés pour leur simplicité, leur liquidité et leur facilité d'accès, devenant ainsi des outils populaires dans le monde de l'investissement.</p>
        <br/>
        <br/>

        <p>Le marché des obligations est un secteur financier où les gouvernements et les entreprises empruntent de l'argent aux investisseurs en émettant des obligations. Ces obligations représentent des prêts que les investisseurs accordent à l'émetteur, qui s'engage à rembourser le montant initial avec des intérêts sur une période spécifiée. Les exemples incluent les obligations gouvernementales, utilisées pour financer des projets publics, et les obligations d'entreprise, émises par des sociétés pour lever des fonds. Les investisseurs reçoivent des paiements d'intérêts réguliers pendant la durée de l'obligation et récupèrent leur investissement initial à l'échéance. Le marché des obligations joue un rôle crucial dans le financement des activités économiques et offre aux investisseurs un moyen de générer des revenus. Les rendements sont influencés par les taux d'intérêt et la qualité de crédit de l'émetteur.</p>

        `    },
    {
        id: 3,
        imgSrc: analyse,
        title: "Comment analyser très brievement un marché ?",
        icons: [good, start, more],
        content: ` 
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${Actionimg}" style="width:20px;height:auto;"/>
        Actions</h1>
        <br/>
        <p>Une action est essentiellement une part de propriété dans une entreprise. Si vous possédez des actions d'une entreprise, vous êtes propriétaire d'une petite partie de cette entreprise. Les entreprises émettent des actions pour collecter de l'argent, et en échange, les détenteurs d'actions (actionnaires) peuvent recevoir des bénéfices sous forme de dividendes. Parfois, les détenteurs d'actions ont également le droit de voter sur certaines décisions importantes de l'entreprise lors des réunions.</p>
        <br/>
        <br/>

        <p>Par exemple, si vous avez des actions d'entreprises comme Apple, Amazon ou Coca-Cola, cela signifie que vous êtes propriétaire d'une partie de ces entreprises. Vous pourriez recevoir des dividendes si ces entreprises réalisent des bénéfices, et vous pourriez avoir le droit de voter sur des questions importantes les concernant. Les actions sont échangées sur des marchés boursiers où leur valeur peut fluctuer en fonction de divers facteurs.</p>
        <h1> Matières premières</h1>
        <p>Le marché des matières premières en bourse concerne la négociation de produits de base tels que l'or, le pétrole, les métaux industriels et les produits agricoles. Ces matières premières sont essentielles à l'économie mondiale et leurs prix varient en fonction de facteurs tels que l'offre, la demande, les conditions climatiques et les événements géopolitiques.</p>
        <br/>
        <br/>

        <p>Par exemple, l'or est souvent recherché comme une valeur refuge en période d'incertitude économique, ce qui peut entraîner une hausse de son prix. Le pétrole, en tant que principal combustible mondial, voit son prix fluctuer en fonction des décisions des pays producteurs et des variations de la demande mondiale.</p>
        <p>Les métaux industriels comme le cuivre et l'aluminium sont utilisés dans la fabrication et la construction, et leurs prix sont influencés par la croissance économique et les politiques commerciales. Les matières premières agricoles, telles que le blé et le maïs, dépendent des conditions climatiques et des tendances alimentaires mondiales.</p>
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${bitcoin}" style="width:20px;height:auto;"/>
        Crypto-monnaies</h1>
        <br/>
        <p>Le marché des cryptomonnaies englobe des actifs numériques utilisant la technologie blockchain, opérant indépendamment des autorités traditionnelles. Un exemple majeur est le Bitcoin (BTC), créé en 2009 par Satoshi Nakamoto. Il est reconnu comme la première et la plus importante cryptomonnaie en termes de capitalisation boursière.</h2>
        <br/>
        <p>D'autres cryptomonnaies, appelées altcoins, comprennent Ethereum (ETH), qui offre des contrats intelligents, et Ripple (XRP), conçu pour les transferts d'argent internationaux. Chaque cryptomonnaie a des utilisations spécifiques, telles que moyen de paiement ou exécution de contrats programmables.</p>

        

        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${imgmany}" style="width:20px;height:auto;"/>
        Devises</h1>

        <p>Le marché des devises, également connu sous le nom de Forex, est le plus grand marché financier mondial où les monnaies nationales sont échangées. Banques, institutions financières, entreprises internationales et traders individuels participent à ce marché, achetant et vendant des devises pour des raisons telles que le commerce international, l'investissement et la spéculation. Les taux de change, qui représentent la valeur relative des devises, fluctuent en raison de divers facteurs tels que l'offre et la demande, les indicateurs économiques, les taux d'intérêt et les événements géopolitiques. Les paires de devises, comme EUR/USD, sont utilisées pour indiquer la valeur d'une devise par rapport à une autre. Les fluctuations des taux de change offrent des opportunités de profit pour les participants du marché des devises</p>

   
        <br/>
         <h1 style="display: flex;
         column-gap: 8px;">
         <img  src="${or}" style="width:20px;height:auto;"/>Bonus:les ETFs et les obligations</h1>
         <br/>

        <p>Les ETF, ou fonds négociés en bourse, sont des instruments d'investissement qui permettent aux investisseurs d'acheter et de vendre des paniers diversifiés d'actifs sur les marchés financiers. Ils suivent généralement la performance d'un indice spécifique, offrant une diversification facile et transparente. Par exemple, l'ETF SPDR S&P 500 (SPY) suit les 500 plus grandes entreprises américaines, tandis que l'ETF iShares Core U.S. Aggregate Bond (AGG) offre une exposition aux obligations américaines diversifiées. Les ETF sont appréciés pour leur simplicité, leur liquidité et leur facilité d'accès, devenant ainsi des outils populaires dans le monde de l'investissement.</p>
        <br/>
        <br/>

        <p>Le marché des obligations est un secteur financier où les gouvernements et les entreprises empruntent de l'argent aux investisseurs en émettant des obligations. Ces obligations représentent des prêts que les investisseurs accordent à l'émetteur, qui s'engage à rembourser le montant initial avec des intérêts sur une période spécifiée. Les exemples incluent les obligations gouvernementales, utilisées pour financer des projets publics, et les obligations d'entreprise, émises par des sociétés pour lever des fonds. Les investisseurs reçoivent des paiements d'intérêts réguliers pendant la durée de l'obligation et récupèrent leur investissement initial à l'échéance. Le marché des obligations joue un rôle crucial dans le financement des activités économiques et offre aux investisseurs un moyen de générer des revenus. Les rendements sont influencés par les taux d'intérêt et la qualité de crédit de l'émetteur.</p>

        `    },
    {
        id: 4,
        imgSrc: imgblogs,
        title: 'Qui croire et ne pas croire dans les "gourous" du trading?',
        icons: [good, start, more],
        content: ` 
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${Actionimg}" style="width:20px;height:auto;"/>
        Actions</h1>
        <br/>
        <p>Une action est essentiellement une part de propriété dans une entreprise. Si vous possédez des actions d'une entreprise, vous êtes propriétaire d'une petite partie de cette entreprise. Les entreprises émettent des actions pour collecter de l'argent, et en échange, les détenteurs d'actions (actionnaires) peuvent recevoir des bénéfices sous forme de dividendes. Parfois, les détenteurs d'actions ont également le droit de voter sur certaines décisions importantes de l'entreprise lors des réunions.</p>
        <br/>
        <br/>

        <p>Par exemple, si vous avez des actions d'entreprises comme Apple, Amazon ou Coca-Cola, cela signifie que vous êtes propriétaire d'une partie de ces entreprises. Vous pourriez recevoir des dividendes si ces entreprises réalisent des bénéfices, et vous pourriez avoir le droit de voter sur des questions importantes les concernant. Les actions sont échangées sur des marchés boursiers où leur valeur peut fluctuer en fonction de divers facteurs.</p>
        <h1> Matières premières</h1>
        <p>Le marché des matières premières en bourse concerne la négociation de produits de base tels que l'or, le pétrole, les métaux industriels et les produits agricoles. Ces matières premières sont essentielles à l'économie mondiale et leurs prix varient en fonction de facteurs tels que l'offre, la demande, les conditions climatiques et les événements géopolitiques.</p>
        <br/>
        <br/>

        <p>Par exemple, l'or est souvent recherché comme une valeur refuge en période d'incertitude économique, ce qui peut entraîner une hausse de son prix. Le pétrole, en tant que principal combustible mondial, voit son prix fluctuer en fonction des décisions des pays producteurs et des variations de la demande mondiale.</p>
        <p>Les métaux industriels comme le cuivre et l'aluminium sont utilisés dans la fabrication et la construction, et leurs prix sont influencés par la croissance économique et les politiques commerciales. Les matières premières agricoles, telles que le blé et le maïs, dépendent des conditions climatiques et des tendances alimentaires mondiales.</p>
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${bitcoin}" style="width:20px;height:auto;"/>
        Crypto-monnaies</h1>
        <br/>
        <p>Le marché des cryptomonnaies englobe des actifs numériques utilisant la technologie blockchain, opérant indépendamment des autorités traditionnelles. Un exemple majeur est le Bitcoin (BTC), créé en 2009 par Satoshi Nakamoto. Il est reconnu comme la première et la plus importante cryptomonnaie en termes de capitalisation boursière.</h2>
        <br/>
        <p>D'autres cryptomonnaies, appelées altcoins, comprennent Ethereum (ETH), qui offre des contrats intelligents, et Ripple (XRP), conçu pour les transferts d'argent internationaux. Chaque cryptomonnaie a des utilisations spécifiques, telles que moyen de paiement ou exécution de contrats programmables.</p>

        

        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${imgmany}" style="width:20px;height:auto;"/>
        Devises</h1>

        <p>Le marché des devises, également connu sous le nom de Forex, est le plus grand marché financier mondial où les monnaies nationales sont échangées. Banques, institutions financières, entreprises internationales et traders individuels participent à ce marché, achetant et vendant des devises pour des raisons telles que le commerce international, l'investissement et la spéculation. Les taux de change, qui représentent la valeur relative des devises, fluctuent en raison de divers facteurs tels que l'offre et la demande, les indicateurs économiques, les taux d'intérêt et les événements géopolitiques. Les paires de devises, comme EUR/USD, sont utilisées pour indiquer la valeur d'une devise par rapport à une autre. Les fluctuations des taux de change offrent des opportunités de profit pour les participants du marché des devises</p>

   
        <br/>
         <h1 style="display: flex;
         column-gap: 8px;">
         <img  src="${or}" style="width:20px;height:auto;"/>Bonus:les ETFs et les obligations</h1>
         <br/>

        <p>Les ETF, ou fonds négociés en bourse, sont des instruments d'investissement qui permettent aux investisseurs d'acheter et de vendre des paniers diversifiés d'actifs sur les marchés financiers. Ils suivent généralement la performance d'un indice spécifique, offrant une diversification facile et transparente. Par exemple, l'ETF SPDR S&P 500 (SPY) suit les 500 plus grandes entreprises américaines, tandis que l'ETF iShares Core U.S. Aggregate Bond (AGG) offre une exposition aux obligations américaines diversifiées. Les ETF sont appréciés pour leur simplicité, leur liquidité et leur facilité d'accès, devenant ainsi des outils populaires dans le monde de l'investissement.</p>
        <br/>
        <br/>

        <p>Le marché des obligations est un secteur financier où les gouvernements et les entreprises empruntent de l'argent aux investisseurs en émettant des obligations. Ces obligations représentent des prêts que les investisseurs accordent à l'émetteur, qui s'engage à rembourser le montant initial avec des intérêts sur une période spécifiée. Les exemples incluent les obligations gouvernementales, utilisées pour financer des projets publics, et les obligations d'entreprise, émises par des sociétés pour lever des fonds. Les investisseurs reçoivent des paiements d'intérêts réguliers pendant la durée de l'obligation et récupèrent leur investissement initial à l'échéance. Le marché des obligations joue un rôle crucial dans le financement des activités économiques et offre aux investisseurs un moyen de générer des revenus. Les rendements sont influencés par les taux d'intérêt et la qualité de crédit de l'émetteur.</p>

        `    },
    {
        id: 5,
        imgSrc: tradingblog,
        title: 'Pourquoi le trading est représenté par un taureau et un ours ?',
        icons: [good, start, more],
        content: ` 
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${Actionimg}" style="width:20px;height:auto;"/>
        Actions</h1>
        <br/>
        <p>Une action est essentiellement une part de propriété dans une entreprise. Si vous possédez des actions d'une entreprise, vous êtes propriétaire d'une petite partie de cette entreprise. Les entreprises émettent des actions pour collecter de l'argent, et en échange, les détenteurs d'actions (actionnaires) peuvent recevoir des bénéfices sous forme de dividendes. Parfois, les détenteurs d'actions ont également le droit de voter sur certaines décisions importantes de l'entreprise lors des réunions.</p>
        <br/>
        <br/>

        <p>Par exemple, si vous avez des actions d'entreprises comme Apple, Amazon ou Coca-Cola, cela signifie que vous êtes propriétaire d'une partie de ces entreprises. Vous pourriez recevoir des dividendes si ces entreprises réalisent des bénéfices, et vous pourriez avoir le droit de voter sur des questions importantes les concernant. Les actions sont échangées sur des marchés boursiers où leur valeur peut fluctuer en fonction de divers facteurs.</p>
        <h1> Matières premières</h1>
        <p>Le marché des matières premières en bourse concerne la négociation de produits de base tels que l'or, le pétrole, les métaux industriels et les produits agricoles. Ces matières premières sont essentielles à l'économie mondiale et leurs prix varient en fonction de facteurs tels que l'offre, la demande, les conditions climatiques et les événements géopolitiques.</p>
        <br/>
        <br/>

        <p>Par exemple, l'or est souvent recherché comme une valeur refuge en période d'incertitude économique, ce qui peut entraîner une hausse de son prix. Le pétrole, en tant que principal combustible mondial, voit son prix fluctuer en fonction des décisions des pays producteurs et des variations de la demande mondiale.</p>
        <p>Les métaux industriels comme le cuivre et l'aluminium sont utilisés dans la fabrication et la construction, et leurs prix sont influencés par la croissance économique et les politiques commerciales. Les matières premières agricoles, telles que le blé et le maïs, dépendent des conditions climatiques et des tendances alimentaires mondiales.</p>
        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${bitcoin}" style="width:20px;height:auto;"/>
        Crypto-monnaies</h1>
        <br/>
        <p>Le marché des cryptomonnaies englobe des actifs numériques utilisant la technologie blockchain, opérant indépendamment des autorités traditionnelles. Un exemple majeur est le Bitcoin (BTC), créé en 2009 par Satoshi Nakamoto. Il est reconnu comme la première et la plus importante cryptomonnaie en termes de capitalisation boursière.</h2>
        <br/>
        <p>D'autres cryptomonnaies, appelées altcoins, comprennent Ethereum (ETH), qui offre des contrats intelligents, et Ripple (XRP), conçu pour les transferts d'argent internationaux. Chaque cryptomonnaie a des utilisations spécifiques, telles que moyen de paiement ou exécution de contrats programmables.</p>

        

        <br/>
        <h1 style="display: flex;
        column-gap: 8px;">
        <img  src="${imgmany}" style="width:20px;height:auto;"/>
        Devises</h1>

        <p>Le marché des devises, également connu sous le nom de Forex, est le plus grand marché financier mondial où les monnaies nationales sont échangées. Banques, institutions financières, entreprises internationales et traders individuels participent à ce marché, achetant et vendant des devises pour des raisons telles que le commerce international, l'investissement et la spéculation. Les taux de change, qui représentent la valeur relative des devises, fluctuent en raison de divers facteurs tels que l'offre et la demande, les indicateurs économiques, les taux d'intérêt et les événements géopolitiques. Les paires de devises, comme EUR/USD, sont utilisées pour indiquer la valeur d'une devise par rapport à une autre. Les fluctuations des taux de change offrent des opportunités de profit pour les participants du marché des devises</p>

   
        <br/>
         <h1 style="display: flex;
         column-gap: 8px;">
         <img  src="${or}" style="width:20px;height:auto;"/>Bonus:les ETFs et les obligations</h1>
         <br/>

        <p>Les ETF, ou fonds négociés en bourse, sont des instruments d'investissement qui permettent aux investisseurs d'acheter et de vendre des paniers diversifiés d'actifs sur les marchés financiers. Ils suivent généralement la performance d'un indice spécifique, offrant une diversification facile et transparente. Par exemple, l'ETF SPDR S&P 500 (SPY) suit les 500 plus grandes entreprises américaines, tandis que l'ETF iShares Core U.S. Aggregate Bond (AGG) offre une exposition aux obligations américaines diversifiées. Les ETF sont appréciés pour leur simplicité, leur liquidité et leur facilité d'accès, devenant ainsi des outils populaires dans le monde de l'investissement.</p>
        <br/>
        <br/>

        <p>Le marché des obligations est un secteur financier où les gouvernements et les entreprises empruntent de l'argent aux investisseurs en émettant des obligations. Ces obligations représentent des prêts que les investisseurs accordent à l'émetteur, qui s'engage à rembourser le montant initial avec des intérêts sur une période spécifiée. Les exemples incluent les obligations gouvernementales, utilisées pour financer des projets publics, et les obligations d'entreprise, émises par des sociétés pour lever des fonds. Les investisseurs reçoivent des paiements d'intérêts réguliers pendant la durée de l'obligation et récupèrent leur investissement initial à l'échéance. Le marché des obligations joue un rôle crucial dans le financement des activités économiques et offre aux investisseurs un moyen de générer des revenus. Les rendements sont influencés par les taux d'intérêt et la qualité de crédit de l'émetteur.</p>

        `    },
];

export default datablog;
