import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import CustomInput from '../../components/custominput'
import key from '../../images/key.png'
import next from '../../images/next.png'
import { Form, Link } from 'react-router-dom';
import unlock from '../../images/unlock.png'
import CustomButton from '../../components/custombutton'
import { register } from "../../Api/authApi.js";
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import { toast } from 'react-toastify';


const SignUpPage = () => {
  const navigate = useNavigate(); // Obtenir l'objet navigate
  // const notify = () => toast("Wow so easy!");

  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    team_code: '',
    country: 'FR'  // France comme valeur par défaut
  });
  const [errors, setErrors] = useState({}); // État pour stocker les erreurs de validation

  const handleCountrySelect = (code) => {
    setUserData({ ...userData, country: code });
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await register(userData);
      setUserData({
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        team_code: '',
        country: 'FR'  // Réinitialiser à la France après soumission
      });
      toast.success('Vous avez créé votre compte avec succès !');
      navigate('/connexion');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
        // Afficher les erreurs d'une manière appropriée, par exemple, avec toast.warning()
        Object.values(error.response.data.errors).forEach((errorMessages) => {
          errorMessages.forEach((errorMessage) => {
            toast.warning(errorMessage);
          });
        });
      } else {
        toast.warning('Une erreur est survenue');
      }
    }
  };

  return (
    <section className=' bg-[#252f3d] text-white relative'>
      <Link to='/' className='absolute left '>
        <img className={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className=' flex justify-center min-h-screen '>
        <div className='flex items-center flex-col justify-center'>
        <div className='flex  justify-center h-[210px] flex-col items-center mt-3'>
          <CustomHeader width="" />
        </div>         
         <h1 className='flex justify-center text-center  font-bold font-sans text-[30px] pb-4 '  style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Trading Series</h1>
          <div className='items-center flex-col space-y-2  w-[60%] sm:w-[40%]  md:w-[40%]   lg:w-[40%]  xl:w-[40%]  2xl:w-[40%] '>
            <form className=" gap-3 flex flex-col" onSubmit={handleSubmit}>
              <CustomInput
                styleinput="pl-14 font-medium text-[11px] "
                icon={<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-black "
                  viewBox="0 0 256 256" id="user"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="96" r="64" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="16"></circle><path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" d="M30.989,215.99064a112.03731,112.03731,0,0,1,194.02311.002"></path></svg>}
                placeholder='Nom d&rsquo;utilisateur'
                name='username'
                type='text'
                value={userData.username}
                onChange={handleChange}
              />
              <CustomInput
                styleinput="pl-14 font-medium text-[11px] "


                icon={<svg
                  className="w-6 h-6 text-black "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 16"
                >
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>}
                placeholder='Adresse e-mail'
                type="email" name="email" value={userData.email} onChange={handleChange}
              />
              <CustomInput
                styleinput="pl-14 font-medium text-[11px] "

                icon={<img src={key} width="20px" height="20px" />}

                placeholder='Mot de passe'
                type="password"
                name="password" value={userData.password} onChange={handleChange}
              />

              <CustomInput
                styleinput="pl-14 text-[11px] font-medium py-1 "
                icon={<img src={key} width="20px" height="20px" />}
                placeholder='Confirmation'
                type="password"
                name="password_confirmation"
                value={userData.password_confirmation}
                onChange={handleChange}
              />
              <ReactFlagsSelect
                selected={userData.country || "FR"}  // Définit la France comme sélection par défaut
                defaultCountry="FR"  // Valeur par défaut définie sur la France
                onSelect={handleCountrySelect}
                placeholder={
                  <span className="flex text-black text-[15px] gap-12">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-left text-black" viewBox="0 0 100 100" id="internet">
                      <path d="M30 27.4c-3.6-1.3-7-3-10.3-5 5.1-5.5 11.6-9.7 19-11.8-3.6 3.9-6.6 9.7-8.7 16.8zM19.7 77.5c5.1 5.6 11.7 9.7 19.1 11.9-3.7-4-6.7-9.8-8.7-16.9-3.7 1.3-7.2 3-10.4 5zM27 52H9c.4 8.4 3.3 16.1 8 22.4 3.8-2.4 7.8-4.3 11.9-5.8-1.1-5.1-1.7-10.7-1.9-16.6zm34.3 37.4c7.4-2.1 14-6.3 19-11.8-3.3-2-6.7-3.7-10.3-5-2.1 7.1-5.1 12.9-8.7 16.8zm19-66.9c-5.1-5.6-11.7-9.7-19.1-11.9 3.7 4 6.7 9.8 8.7 16.9 3.7-1.3 7.2-3 10.4-5zM29 31.3c-4.1-1.5-8.1-3.4-11.9-5.8C12.4 31.8 9.5 39.6 9 48h18c.2-6 .8-11.6 2-16.7zM73 48h18c-.4-8.4-3.3-16.1-8-22.4-3.8 2.4-7.8 4.3-11.9 5.8 1.1 5.1 1.7 10.7 1.9 16.6zm-2 20.7c4.1 1.5 8.1 3.4 11.9 5.8 4.8-6.3 7.7-14.1 8.1-22.5H73c-.2 6-.8 11.6-2 16.7zM48 90.8V69c-4.8.2-9.6.9-14.2 2.2 3.1 10.6 8.3 18.1 14.2 19.6zM69 52H52v13c5.2.2 10.2 1 15.1 2.4 1.1-4.7 1.7-9.9 1.9-15.4zM52 69v21.7c5.9-1.4 11.1-8.9 14.1-19.5-4.5-1.2-9.3-2-14.1-2.2zm0-59.8V31c4.8-.2 9.6-.9 14.2-2.2C63.1 18.2 57.9 10.6 52 9.2zM31 48h17V35c-5.2-.2-10.2-1-15.1-2.4-1.1 4.7-1.7 9.9-1.9 15.4zm2.9-19.3C38.4 30 43.2 30.8 48 31V9.2c-5.9 1.4-11.1 9-14.1 19.5zm33.2 3.9C62.2 34 57.2 34.8 52 35v13h17c-.2-5.5-.8-10.7-1.9-15.4zM32.9 67.4c4.9-1.4 10-2.2 15.1-2.4V52H31c.2 5.5.8 10.6 1.9 15.4z"></path>
                    </svg>
                    France
                  </span>}
                className=" bg-white rounded-md text-black font-semibold"
              />

              <CustomInput
                styleinput="pl-14 font-medium text-[11px] "

                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="group"><path fill="#000" d="M18.052 14.762c.086.134.21.238.353.306A3.25 3.25 0 0 1 20.25 18v2a.75.75 0 0 0 1.5 0v-2A4.75 4.75 0 0 0 17 13.25c-.109 0-.158.14-.076.211a6.03 6.03 0 0 1 1.128 1.301Z"></path><path fill="#000" fill-rule="evenodd" d="M7 14.75A3.25 3.25 0 0 0 3.75 18v2a.75.75 0 0 1-1.5 0v-2A4.75 4.75 0 0 1 7 13.25h6A4.75 4.75 0 0 1 17.75 18v2a.75.75 0 0 1-1.5 0v-2A3.25 3.25 0 0 0 13 14.75H7Z" clip-rule="evenodd"></path><path fill="#000" d="M14.87 9.557c.09-.169.23-.304.396-.4a2.499 2.499 0 0 0 0-4.314 1.03 1.03 0 0 1-.396-.4 5.514 5.514 0 0 0-.933-1.283C13.88 3.1 13.917 3 14 3a4 4 0 0 1 0 8c-.083 0-.12-.1-.063-.16.37-.378.685-.81.933-1.283Z"></path><path fill="#000" fill-rule="evenodd" d="M10 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 1.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" clip-rule="evenodd"></path></svg>
                }
                placeholder='Code de l&rsquo;équipe'
                type="number"
                name="team_code"
                value={userData.team_code}
                onChange={handleChange}
              />

              {/* <Link to='/connexion'> */}
              <CustomButton
                icon={<img src={unlock} width="18px" height="18px" />}
                text='Créer mon compte'
                styleinput="bg-gray-300  py-3  text-sm font-medium  "

              />

            </form>
          </div>


          <div className='flex items-center  flex-col justify-center mt-2'>
            <CustomFooter styelefooter='text-[15px]  mx-1 ' />

          </div>

        </div>

      </div>
    </section>

  )
}

export default SignUpPage