/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import photoequipe from '../../images/photoequipe.png'
import class1 from '../../images/classementimg/class1.png'
import class2 from '../../images/classementimg/class2.png'
import class3 from '../../images/classementimg/class3.png'
import francelogo from '../../images/drapeaux/francedrapeau.png'
import bitcoin from '../../images/imgCrypto/Bitcoin.png'
import ethereum from '../../images/imgCrypto/ethereum.png'
import BNP from '../../images/imgCrypto/BNB.png'
import salona from '../../images/imgCrypto/salona.png'
import xrp from '../../images/imgCrypto/xrp.png'
import usdc from '../../images/imgCrypto/usdc.png'
import cardano from '../../images/imgCrypto/cardano.png'
import avalanche from '../../images/imgCrypto/avalanche.png'
import doge from '../../images/imgCrypto/doge.png'
import Chainlink from '../../images/imgCrypto/Chainlink.png'
import TETHER from '../../images/imgCrypto/TETHER.png'
import tron from '../../images/imgCrypto/tron.png'
import RoyaumeUnidrapeau from '../../images/drapeaux/RoyaumeUnidrapeau.png'
import Drawer from "../../components/Drawer";
import { Link } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import StackedBarChart from "../../components/chartgraphique/BarChart";

const PorteFeuilleActions = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


    const CryptoFrance = [
        { title: "Crypto-monnaies" },
        { name: "Bitcoin", price: "42.240,4", time: "17:35:20", change: "(+5,19%)", imgaction: bitcoin },
        { name: "Ethereum", price: "2.275,88", time: "17:35:20", change: "(+1,99%)", imgaction: ethereum },
        { name: "Tether USDt", price: "0,9995", time: "17:35:20", change: "(-0,05%)", imgaction: TETHER },
        { name: "BNB", price: "300,00", time: "17:35:20", change: "(+3,03%)", imgaction: BNP },
        { name: "Solana", price: "96,629", time: "17:35:20", change: "(+9,68%)", imgaction: salona },
        { name: "XRP", price: "0,4970", time: "17:35:20", change: "(-3,18%)", imgaction: xrp },
        { name: "USDC", price: "1,0009", time: "17:35:20", change: "(-0,02%)", imgaction: usdc },
        { name: "Cardano", price: "0,4934", time: "17:35:20", change: "(+4,37%)", imgaction: cardano },
        { name: "Avalanche", price: "33,34", time: "17:35:20", change: "(+9,91%)", imgaction: avalanche },
        { name: "DogeCoin", price: "0,7881", time: "17:35:20", change: "(+1,26%)", imgaction: doge },
        { name: "ChainLink", price: "15,58", time: "17:35:20", change: "(+12,0%)", imgaction: Chainlink },
        { name: "TRON", price: "0,113", time: "17:35:20", change: "(+1,00%)", imgaction: tron },

    ];
    const CryptoUS = [
        { title: "Crypto-monnaies" },
        { name: "Bitcoin", price: "42.240,4", time: "17:35:20", change: "(+5,19%)", imgaction: bitcoin },
        { name: "Ethereum", price: "2.275,88", time: "17:35:20", change: "(+1,99%)", imgaction: ethereum },
        { name: "Tether USDt", price: "0,9995", time: "17:35:20", change: "(-0,05%)", imgaction: TETHER },
        { name: "BNB", price: "300,00", time: "17:35:20", change: "(+3,03%)", imgaction: BNP },
        { name: "Solana", price: "96,629", time: "17:35:20", change: "(+9,68%)", imgaction: salona },
        { name: "XRP", price: "0,4970", time: "17:35:20", change: "(-3,18%)", imgaction: xrp },
        { name: "USDC", price: "1,0009", time: "17:35:20", change: "(-0,02%)", imgaction: usdc },
        { name: "Cardano", price: "0,4934", time: "17:35:20", change: "(+4,37%)", imgaction: cardano },
        { name: "Avalanche", price: "33,34", time: "17:35:20", change: "(+9,91%)", imgaction: avalanche },
        { name: "DogeCoin", price: "0,7881", time: "17:35:20", change: "(+1,26%)", imgaction: doge },
        { name: "ChainLink", price: "15,58", time: "17:35:20", change: "(+12,0%)", imgaction: Chainlink },
        { name: "TRON", price: "0,113", time: "17:35:20", change: "(+1,00%)", imgaction: tron },
    ];

    const CryptoUK =
        [
            { title: "Crypto-monnaies" },
            { name: "Bitcoin", price: "42.240,4", time: "17:35:20", change: "(+5,19%)", imgaction: bitcoin },
            { name: "Ethereum", price: "2.275,88", time: "17:35:20", change: "(+1,99%)", imgaction: ethereum },
            { name: "Tether USDt", price: "0,9995", time: "17:35:20", change: "(-0,05%)", imgaction: TETHER },
            { name: "BNB", price: "300,00", time: "17:35:20", change: "(+3,03%)", imgaction: BNP },
            { name: "Solana", price: "96,629", time: "17:35:20", change: "(+9,68%)", imgaction: salona },
            { name: "XRP", price: "0,4970", time: "17:35:20", change: "(-3,18%)", imgaction: xrp },
            { name: "USDC", price: "1,0009", time: "17:35:20", change: "(-0,02%)", imgaction: usdc },
            { name: "Cardano", price: "0,4934", time: "17:35:20", change: "(+4,37%)", imgaction: cardano },
            { name: "Avalanche", price: "33,34", time: "17:35:20", change: "(+9,91%)", imgaction: avalanche },
            { name: "DogeCoin", price: "0,7881", time: "17:35:20", change: "(+1,26%)", imgaction: doge },
            { name: "ChainLink", price: "15,58", time: "17:35:20", change: "(+12,0%)", imgaction: Chainlink },
            { name: "TRON", price: "0,113", time: "17:35:20", change: "(+1,00%)", imgaction: tron },

        ];
    const [currentData, setCurrentData] = useState(CryptoFrance);
    const [selectedButton, setSelectedButton] = useState('France');
    const [affichetitle, setaffichetitle] = useState('France-CAC40');

    const handleButtonClick = (data, button) => {
        setCurrentData(data);
        setSelectedButton(button);
        setaffichetitle(data[0].title)
    };


    const getChangeColor = (change) => {
        if (!change) return '';
        return change.includes('+') ? 'text-green-600' : 'text-red-600';
    };
    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
       
            <div className="grid  lg:grid-cols-1  md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center  gap-7 sm:px-10 md:px-16">


                <div className="lg:w-[98%] w-[100%] lg:pt-2 px-2 p-2 lg:border md:border  sm:border rounded-lg shadow bg-balck text-white flex    mx-auto flex-col ">
                    <div className="flex flex-row">
                        <button className={`  border rounded-xs  w-1/3   justify-center items-center  cursor-pointer  ${selectedButton === 'France' ? 'bg-white text-black' : 'bg-[#252f3d] text-gray-300 '} font-normal lg:text-[15px] lg:font-medium text-[13px] text-center px-4  py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2 `}
                            onClick={() => handleButtonClick(CryptoFrance, 'France')}
                        >
                            France
                        </button>
                        <button className={`  border rounded-xs ${selectedButton === 'USA' ? 'bg-white text-black' : 'bg-[#252f3d] text-gray-300 '} w-1/3 cursor-pointer   justify-center items-center   bg-[#252f3d] font-normal lg:text-[15px] lg:font-medium text-[13px] text-center px-4  py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2 `}
                            onClick={() => handleButtonClick(CryptoUS, 'USA')}
                        >
                            USA
                        </button>
                        <button className={`   border rounded-xs ${selectedButton === 'Royaume-Uni' ? 'bg-white text-black' : 'bg-[#252f3d] text-gray-300 '} w-1/3   justify-center items-center cursor-pointer bg-[#252f3d] font-normal lg:text-[15px] lg:font-medium text-[13px] text-center py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2 `}
                            onClick={() => handleButtonClick(CryptoUK, 'Royaume-Uni')}
                        >
                            Royaume-Uni
                        </button>


                    </div>
                    <div className="flex  justify-between mx-3 py-3">
                        <p>{affichetitle}</p>
                        <button className={` hover:bg-white hover:text-black  border rounded-xs  text-gray-300 w-auto px-4   justify-center items-center   bg-[#252f3d] font-normal text-[13px] text-center flex  gap-4  `}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                                </svg>
                            </span>
                            <span>Trier par
                            </span>
                        </button>
                    </div>

                    {currentData.slice(1).map((stock, index) => (
                        <div key={index} className="flex justify-between px-3 py-4 border-b border-gray-400">
                            <div className="flex gap-3 items-center">
                                <div className="relative">
                                    {index <= 3 && (<span className="absolute -top-2 -left-1">
                                        <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                        </svg>
                                    </span>
                                    )}
                                    <img src={stock.imgaction} className="w-auto h-auto" alt={stock.name} />
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-[14px] lg:text-md font-semibold">{stock.name}</p>
                                    <p className="text-[12px] lg:text-md font-semibold flex gap-2 items-center">  <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#a2ef4d" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                    </svg></span>{stock.time}</p>

                                </div>
                            </div>
                            <div className="flex items-center flex-col font-medium">
                                <p className="text-[12px] lg:text-md ">{stock.price}</p>
                                <p className={`text-[11px] lg:text-md  ${getChangeColor(stock.change)}`}>{stock.change}</p>
                            </div>
                        </div>
                    ))}

                </div>

            </div>


        </div>

    )
};
export default PorteFeuilleActions;
