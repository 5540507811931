
// export default ProgressiveLineChart;
// v2
// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';
// const API_URL = 'http://127.0.0.1:8000/api';

// // const API_URL = 'https://tradingseries.fr/admin/public/api';

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// // timeOptions defined here
// const timeOptions = [
//   // { key: 'weekend', label:'',function: 'getDataForCurrentOrFriday' },
//   { key: '1', label: '1min', function: 'getDatabayPeriod' },
//   { key: '7', label: '5min', function: 'getDatabayPeriod' },
//   { key: '17', label: '15min', function: 'getDatabayPeriod' },
//   { key: '35', label: '30min', function: 'getDatabayPeriod' },
//   { key: '60', label: '1h', function: 'getDatabayPeriod' },
//   { key: '240', label: '4h', function: 'getDatabayPeriod' },
//   { key: 'All', label: '1D', function: 'getDatabayPeriod' },
//   { key: '1W', label: '1W', function: 'getDatabayPeriod' }
// ];

// const ProgressiveLineChart = ({ selectedTime, marcheId, userId, leagueid }) => {
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const [fullLabels, setFullLabels] = useState([]);
//   const chartRef = useRef();

//   const fetchData = async (timeKey) => {
//     let timeOption = timeOptions.find(option => option.key === timeKey);

//     if (timeOption) {
//       try {
//         const response = await axios.get(`${API_URL}/${timeOption.function}/${marcheId}/${leagueid}/${timeOption.key}`);
//         const data = response.data;
//         // console.log('ProgressiveLineChart',data)

//         if (data && data.values) {
//           const date = data.date;
//           const values = data.values.map(item => item.value);
//           const fullLabels = data.values.map(item => `${date} ${item.heures}`);

//           setData(values);
//           setLabels(data.values.map(item => item.heures));
//           setFullLabels(fullLabels);
//         } else {
//           // Si aucune donnée n'est récupérée, réinitialiser les données
//           setData([]);
//           setLabels([]);
//           setFullLabels([]);
//         }
//       } catch (error) {
//         console.error('Erreur lors de la récupération des données:', error);
//       }
//     }
//   };

//   useEffect(() => {

//     // Fetch data chaque fois que selectedTime, marcheId ou leagueid change
//     fetchData(selectedTime);
//     // Set up interval to fetch data every minute
//     const intervalId = setInterval(() => {
//       fetchData(selectedTime);
//     }, 60000); // 60000ms = 1 minute

//     // Clean up interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [selectedTime, marcheId, leagueid]); // Ajoutez leagueid ici


//   const handlePointClick = (event, elements) => {
//     if (elements.length > 0) {
//       const elementIndex = elements[0].index;
//       const value = data[elementIndex];
//       const fullLabel = fullLabels[elementIndex];
//       // console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
//     }
//   };

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 0.5,
//         radius: 0.5,
//         data: data,
//       }
//     ]
//   };

//   // const options = {
//   //   animation: false,
//   //   interaction: {
//   //     intersect: false,
//   //     mode: 'nearest'
//   //   },
//   //   plugins: {
//   //     legend: {
//   //       display: false
//   //     },
//   //     tooltip: {
//   //       callbacks: {
//   //         label: (context) => {
//   //           const index = context.dataIndex;
//   //           const value = data[index];
//   //           return `${value}`;
//   //         }
//   //       }
//   //     }
//   //   },
//   //   onClick: handlePointClick,
//   //   scales: {
//   //     x: {
//   //       type: 'category',
//   //       grid: {
//   //         display: false
//   //       },
//   //       border: {
//   //         display: false
//   //       },
//   //       title: {
//   //         display: true,
//   //       },
//   //       ticks: {
//   //         display: false,
//   //         font: {
//   //           // display: false,
//   //           size: 8,
//   //         }
//   //       }

//   //     },
//   //     y: {
//   //       title: {
//   //         display: false,
//   //       },
//   //       border: {
//   //         display: false
//   //       },
//   //       grid: {
//   //         display: false
//   //       },
//   //       ticks: {
//   //         display: false,
//   //       },
//   //     }
//   //   }
//   // };
//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest'
//     },
//     plugins: {
//       legend: {
//         display: false
//       },
//       tooltip: {
//         callbacks: {
//           // title: (context) => {
//           //   const index = context[0].dataIndex;
//           //   return `Heure : ${labels[index]}`; // Affiche l'heure dans le tooltip en fonction des labels de l'axe X
//           // },
//           label: (context) => {
//             return `${context.raw}`; // Affiche la valeur dans le tooltip
//           }
//         },
//         displayColors: false
//       }
//     },
//     onClick: handlePointClick,
//     scales: {
//       x: {
//         type: 'category', // Assurez-vous que le type de l'axe X est 'category'
//         labels: labels,   // Utilisez les labels (heures) comme étiquettes pour l'axe X
//         grid: {
//           display: false
//         },
//         border: {
//           display: false
//         },
//         title: {
//           display: false,
//           text: ''  // Affiche un titre sur l'axe X, par exemple "Heures"
//         },
//         ticks: {
//           display: false,  // Active l'affichage des labels sur l'axe X
//           font: {
//             size: 10,     // Taille de la police pour les labels
//           }
//         }
//       },
//       y: {
//         title: {
//           display: false,
//         },
//         border: {
//           display: false
//         },
//         grid: {
//           display: false
//         },
//         ticks: {
//           display: false, // Active l'affichage des labels sur l'axe Y
//         }
//       }
//     }
//   };


//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%', }}>
//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;

// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';

// const API_URL = 'http://127.0.0.1:8000/api';
// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// const ProgressiveLineChart = ({ marcheId, leagueid, selectedTime }) => {
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const chartRef = useRef();

//   const fetchData = async () => {
//     const url = `${API_URL}/getDatabaydatevirtuel/${marcheId}/${leagueid}/${selectedTime}`;
//     try {
//       const response = await axios.get(url);
//       const fetchedData = response.data;
//       console.log('frtch', response.data)

//       if (fetchedData && fetchedData.values) {
//         const values = [];
//         const labels = [];

//         // Traiter les données selon l'intervalle
//         if (['5h', '1j'].includes(selectedTime)) {
//           // Pour les intervalles courts, utiliser `jour_dans_mois_virtuel`, `mois_virtuel`, `annee_virtuelle`
//           fetchedData.values.forEach(day => {
//             labels.push(`${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`);
//             values.push(Number(day.values[0].value)); // Utiliser la première valeur du jour
//           });
//         // Traiter les données selon l'intervalle
//          } else if ([ '1w', '1m'].includes(selectedTime)) {
//           // Pour les intervalles courts, utiliser `jour_dans_mois_virtuel`, `mois_virtuel`, `annee_virtuelle`
//           fetchedData.values.forEach(day => {
//             labels.push(`${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`);
//             values.push(Number(day.values[0].value)); // Utiliser la première valeur du jour
//           });
//         } else if (selectedTime === '1y') {
//           // Pour `1y`, utiliser `mois` et `annee_virtuelle`
//           fetchedData.values[0].mois_values.forEach(month => {
//             labels.push(`${month.mois}/${fetchedData.annee_virtuelle}`);
//             values.push(Number(month.values[0].value)); // Utiliser la première valeur du mois
//           });
//         } else if (['5y', '10y', '25y'].includes(selectedTime)) {
//           // Pour les intervalles longs, utiliser uniquement `annee_virtuelle`
//           fetchedData.values.forEach(yearData => {
//             labels.push(`${yearData.annee_virtuelle}`);
//             values.push(Number(yearData.mois_values[0].values[0].value)); // Utiliser la première valeur de la première entrée de chaque année
//           });
//         }

//         setData(values);
//         setLabels(labels);
//       } else {
//         setData([]);
//         setLabels([]);
//       }
//     } catch (error) {
//       console.error('Erreur lors de la récupération des données:', error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [selectedTime, marcheId, leagueid]);

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 0.5,
//         radius: 0.5,
//         data: data,
//       },
//     ],
//   };

//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest',
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.raw}`,
//         },
//         displayColors: false,
//       },
//     },
//     scales: {
//       x: {
//         type: 'category',
//         labels: labels,
//         grid: { display: false },
//         ticks: {
//           display: true,
//           font: { size: 10 },
//         },
//       },
//       y: {
//         grid: { display: false },
//         ticks: { display: false },
//       },
//     },
//   };

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%' }}>
//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;
import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// const API_URL = 'http://127.0.0.1:8000/api';
const API_URL = 'https://tradingseries.fr/admin/public/api';
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const ProgressiveLineChart = ({ marcheId, leagueid, selectedTime = '1m' }) => { // Valeur par défaut pour `selectedTime`
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const chartRef = useRef();

  const fetchData = async () => {
    const url = `${API_URL}/getDatabaydatevirtuel/${marcheId}/${leagueid}/${selectedTime || '1m'}`; // Définit '5h' par défaut
    try {
      const response = await axios.get(url);
      const fetchedData = response.data;
      if (fetchedData && fetchedData.values) {
        const values = [];
        const labels = [];
        let currentDateLabel = null;

        // Traiter les données selon l'intervalle
        if (['5h', '1j'].includes(selectedTime)) {
          fetchedData.values.forEach(day => {
            day.values.forEach(minuteData => {
              const label = `${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`;
              labels.push(label);
              values.push(Number(minuteData.value));
            });
          });
        } else if (['1w', '1m'].includes(selectedTime)) {
          // Pour `1w` et `1m`, afficher la date une seule fois par jour
          fetchedData.values.forEach(day => {
            const dayLabel = `${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`;
            day.values.forEach((minuteData, index) => {
              // Ajouter la date uniquement au début de chaque jour
              if (index === 0 && currentDateLabel !== dayLabel) {
                labels.push(dayLabel);
                currentDateLabel = dayLabel;
              } else {
                labels.push(''); // Labels vides pour éviter la répétition de la date
              }
              values.push(Number(minuteData.value));
            });
          });


        } else if (selectedTime === '1y') {
          fetchedData.values[0].mois_values.forEach(month => {
            const monthLabel = `${month.mois}/${fetchedData.annee_virtuelle}`;
            month.values.forEach((hourData, index) => {
              if (index === 0 && currentDateLabel !== monthLabel) {
                labels.push(monthLabel);
                currentDateLabel = monthLabel;
              } else {
                labels.push('');
              }
              values.push(Number(hourData.value));
            });
          });
        } else if (['5y', '10y', '25y'].includes(selectedTime)) {
          fetchedData.values.forEach(yearData => {
            yearData.mois_values.forEach(monthData => {
              const monthLabel = `${monthData.mois}/${yearData.annee_virtuelle}`;
              monthData.values.forEach(hourData => {
                labels.push(monthLabel); // Ajouter la date pour l’axe X seulement
                values.push(Number(hourData.value));
              });
            });
          });
        }

        setData(values);
        setLabels(labels);
      } else {
        setData([]);
        setLabels([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedTime, marcheId, leagueid]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        borderColor: 'gray',
        borderWidth: 0.5,
        radius: 0.5,
        data: data,
      },
    ],
  };

  const options = {
    animation: false,
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => null, // Supprime le titre du tooltip pour masquer la date
          label: (context) => `${context.raw}`,
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        type: 'category',
        labels: labels,
        grid: { display: false },
        ticks: {
          display: true,
          font: { size: 8 },
          callback: (value, index) => labels[index] ? labels[index] : '', // Affiche seulement les dates non vides
        },
      },
      y: {
        grid: { display: false },
        ticks: { display: false, font: { size: 10 } },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Line ref={chartRef} data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ProgressiveLineChart;

