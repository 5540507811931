

import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getArticleById, addLike, addRate, addDislike, addComment } from '../../Api/authApi.js';
import { FaRegThumbsUp, FaRegThumbsDown, FaRegStar, FaThumbsUp, FaThumbsDown, FaStar } from 'react-icons/fa'; // Importation des icônes
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ArticleDetail = () => {
    const [article, setArticle] = useState({});
    const [error, setError] = useState(null);
    const { id } = useParams();
    const [comments, setComments] = useState([]); // Nouvel état pour stocker les commentaires
    const userId = useSelector((state) => state.auth.user.id);


    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [starClicked, setStarClicked] = useState(false);
    const [newComment, setNewComment] = useState(''); // Nouvel état pour le commentaire

    const handleLikeClick = async () => {
        try {
            const response = await addLike(id, userId);
            setLikeClicked(true);
            setDislikeClicked(false); // Désélectionner dislike
            // console.log(response.data)
            // setArticle({ ...article, likes: response.likes }); // Met à jour le nombre de likes
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDislikeClick = async () => {
        try {
            const response = await addDislike(id, userId);
            setDislikeClicked(true);
            setLikeClicked(false); // Désélectionner like
            // console.log(response.data)

        } catch (err) {
            setError(err.message);
        }
    };

    const handleStarClick = async () => {
        try {
            const response = await addRate(id, userId);
            setStarClicked(true);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };
 
    const handleSubmitComment = async (e) => {
        e.preventDefault(); // Empêcher le rechargement de la page

        if (newComment.trim() === '') {
            toast.warning('Le commentaire ne peut pas être vide');

            return;
        }

        try {
            const response = await addComment(id, userId, newComment); // Appel à l'API pour ajouter un commentaire
            setComments([...comments, response.data]); // Ajouter le commentaire à la liste actuelle des commentaires
            // console.log(response)
            toast.success(response.data.message);

            
            setNewComment(''); // Réinitialiser le champ de texte après l'envoi
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await getArticleById(id);
                setArticle(response);
                // console.log("articledata", response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchArticle();
    }, [id]);

    return (
        <div className="bg-[#252f3d] text-white w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
            <div className="flex flex-col items-center justify-center sm:px-10 md:px-16 px-4">
                <h1 className="text-md lg:text-xl py-3 text-center font-semibold" style={{ fontStyle: 'italic' }}>{article.title}</h1>
                <img className="w-[100%] h-auto object py-2" src={`https://tradingseries.fr/admin/storage/app/public/${article.image}`} alt={article.title} />
                <div className="mt-1 font-Averia justify-evenly text-sm lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: article.content }}></div>
                <div className="flex gap-2 mt-4 border-t border-gray-400 py-3">
                    <p className="text-[14px] items-center">Cet article a-t-il été utile ?</p>

                    <button onClick={handleLikeClick}>
                        {likeClicked ? <FaThumbsUp className="text-white text-xl" /> : <FaRegThumbsUp className="text-white text-xl" />}
                    </button>

                    <button onClick={handleDislikeClick}>
                        {dislikeClicked ? <FaThumbsDown className="text-white text-xl" /> : <FaRegThumbsDown className="text-white text-xl" />}
                    </button>

                    <button onClick={handleStarClick}>
                        {starClicked ? <FaStar className="text-white text-xl" /> : <FaRegStar className="text-white text-xl" />}
                    </button>
                </div>
            </div>
            <div >
             
            <form onSubmit={handleSubmitComment}> {/* Gérer la soumission du formulaire */}
                 
                    <div className="flex items-center  p-1.5 m-2 rounded-lg justify-center bg-white text-black w-[96%]">
                        <textarea
                            id="chat"
                            rows={1}
                            className="block mx-1 p-3 w-[100%] text-sm text-gray-900 bg-white rounded-lg focus:ring-white dark:placeholder-gray-400 "
                            placeholder="Écrire un commentaire"
                            value={newComment} 
                            onChange={(e) => setNewComment(e.target.value)} 

                        />
                        <button
                            type="submit"
                            className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 "
                        >
                            <svg
                                className="w-5 h-5 rotate-90 rtl:-rotate-90"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 18 20"
                            >
                                <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                            </svg>
                            <span className="sr-only">Send message</span>
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default ArticleDetail;
